import Cabecera from '../components/cabecera'
import Detalles_Citatorio from '../components/detalles-citatorio'


const Ver_Citatorio = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Citatorio/>
        </>
    )
}

export default Ver_Citatorio