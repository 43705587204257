import './falta.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Falta = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    
    const [faltas, setFaltas] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/faltas/")
        .then((res) => setFaltas(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )

    return(

        <>
        {faltas.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        faltas.map((falta) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === falta.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlFalta', JSON.stringify(falta.Alumnos_id))
          };

          //////
          const mandarFalta = () => {
            localStorage.setItem('idFalta', JSON.stringify(falta.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarFalta()
          }
            return(
        
        <section key={falta.id} className='falta'>
            <div className='falta-contenedor'>
                <span>FALTA {falta.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"}</span>

                <div className='falta-botones'>
                <Link onClick={mandarAlumno} className="link-falta" to={`/administrador/prefecto/faltas/${falta.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>

)
})
)}
</>
);
};

export default Falta