import '../alerts/alerta.css'
import Axios from "axios"

const AlertaFVD = ({alerta, setAlerta,}) => {

    const mostrarAlerta = () =>{
        setAlerta(!alerta)
    }

    
    const idFVD = localStorage.getItem("idFVD")

    const deleteFVD = async (idFVD) => {
        await Axios.delete(`https://plantel-candelaria.onrender.com/fvds/${idFVD}`)
        
        window.location.reload();
    }

    const eliminarDatos = ( )=>{
        mostrarAlerta()
        deleteFVD(idFVD)
      }

    return(
        <section className={`alerta-seccion ${ alerta ? 'mostrar' : ''}`}>
            <div className='alerta-contenedor'>
                <div className='alerta-cabecera'>
                    <h3>ELIMINAR</h3>

                </div >
                <div className='alerta-cuerpo'>
                    <h4>USUARIO Y/O CONTRASEÑA INCORRECTA</h4>
                    <button onClick={eliminarDatos}>ACEPTAR</button>
                    <button onClick={mostrarAlerta}>NO</button>
                </div>
            </div>    
        </section>
    )
}

export default AlertaFVD