import './App.css';
import Presentacion from './components/presentacion.js'
import Elegir_Usuario from './components/elegir-usuario.js'
import Login_Administrador from './components/login-administrador.js'
import Alumno from './components/alumno.js'
import Buscador from './components/buscador';
import Notificaciones_prefecto from './components/notificaciones-prefecto';
import Boton_agregar from './components/boton-agregar';
import Menu_agregar from './components/menu-agregar';
import Cabecera from './components/cabecera';
import Menu_horizontal from './components/menu-horizontal';
import Formulario_Reporte from './components/formulario-reporte';
import Datos_Alumno from './components/datos-alumno';
import Formulario_Citatorio from './components/formulario-citatorio';
import Formulario_Falta from './components/formulario-falta';
import Formulario_Retardo from './components/formulario-retardo';
import Formulario_Tarea from './components/formulario-tarea';
import Formulario_Fai from './components/formulario-fai';
import Formulario_Fvd from './components/formulario-fvd';
import Formulario_Alumno from './components/formulario-alumno';
import Inicio from './pages/inicio'
import Login_AdministradorP from './pages/login-administrador';
import Login_TutorP from './pages/login-tutor';
import {Route, Routes } from 'react-router-dom';

import Prefecto from './pages/prefecto';
import Agregar_Reporte from './pages/agregar-reporte';
import Agregar_Citatorio from './pages/agregar-citatorio';
import Agregar_Falta from './pages/agregar-falta';
import Agregar_Retardo from './pages/agregar-retardo';
import Agregar_Justificacion from './pages/agregar-justificacion';
import Agregar_Tarea from './pages/agregar-tarea-atrasada';

import Orientadora from './pages/orientadora';

import Directora from './pages/directora';
import Agregar_FAI from './pages/agregar-fai';
import Agregar_FVD from './pages/agregar-fvd';

import Reportes from './pages/reportes'
import Citatorios from './pages/citatorios'
import Faltas from './pages/faltas'
import Retardos from './pages/retardos'
import Justificaciones from './pages/justificaciones'
import Tareas_Atrasadas from './pages/tareas-atrasadas'
import Cerrar_Sesion from './pages/cerrar-sesion';
import Detalles_Reporte from './components/detalles-reporte';
import Detalles_Citatorio from './components/detalles-citatorio';
import Detalles_Falta from './components/detalles-falta';
import Detalles_Retardo from './components/detalles-retardo';
import Detalles_Justificacion from './components/detalles-justificacion';
import Detalles_Tarea from './components/detalles-tarea';
import Detalles_Fai from './components/detalles-fai';
import Detalles_Fvd from './components/detalles-fvd';
import AlumnosP from './pages/alumnos';
import Fai from './components/fai';
import Fvds from './pages/fvds';
import Fais from './pages/fais';
import TutoresP from './pages/tutores';
import UsuariosP from './pages/usuarios';
import Agregar_Usuario from './pages/agregar-usuario.js';
import Rutas_Protegidas from './components/utils/rutas-protegidas.js';
import Rutas_ProtegidasD from './components/utils/rutas-protegidasD.js';
import Rutas_ProtegidasO from './components/utils/rutas-protegidasO.js';
import Agregar_Tutor from './pages/agregar-tutor.js';
import Agregar_Alumno from './pages/agregar-alumno.js';

import { useState } from 'react';
import Ver_Reporte from './pages/ver-reporte.js';
import Ver_Citatorio from './pages/ver-citatorio.js';
import Ver_Falta from './pages/ver-falta.js';
import Ver_Retardo from './pages/ver-retardo.js';
import Ver_Justificacion from './pages/ver-justificacion.js';
import Ver_Tarea_Atrasada from './pages/ver-tarea-atrasada.js';

import Ver_Fai from './pages/ver-fai.js';
import Ver_Fvd from './pages/ver-fvd.js';
import Ver_Alumno from './pages/ver-alumno.js';
import Ver_Tutor from './pages/ver-tutor.js';

import Fais_Alumno from './pages/fais-alumno.js';
import Fvds_Alumno from './pages/fvds-alumno.js';
import Reportes_Alumno from './pages/reportes-alumno.js';
import Citatorios_Alumno from './pages/citatorios-alumno.js';
import Faltas_Alumno from './pages/faltas-alumno.js';
import Retardos_Alumno from './pages/retardos-alumno.js';
import Justificaciones_Alumno from './pages/justificaciones-alumno.js';
import Tareas_Alumno from './pages/tareas-alumno.js';
import Rutas_ProtegidasT from './components/utils/rutas-protegidasT.js';
import Tutor from './pages/tutor.js';
import Ver_ReporteTutor from './pages/ver-reportetutor.js';
import Ver_CitatorioTutor from './pages/ver-citatoriotutor.js';
import Ver_FaltaTutor from './pages/ver-faltatutor.js';
import Ver_RetardoTutor from './pages/ver-retardotutor.js';
import Ver_JustificacionTutor from './pages/ver-justificaciontutor.js';
import Ver_TareaTutor from './pages/ver-tareatutor.js';
import Ver_ReporteT from './pages/ver-reporteT.js';
import Ver_CitatorioT from './pages/ver-citatorioT.js';
import Ver_FaltaT from './pages/ver-faltaT.js';
import Ver_RetardoT from './pages/ver-retardoT.js';
import Ver_JustificacionT from './pages/ver-justificacionT.js';
import Ver_TareaT from './pages/ver-tareaT.js';
import Usuarios_Lista from './pages/usuarios-lista.js';
import Detalles_Alumno from './components/detalles-alumno.js';



const App = () => {
    
  return (
    <div className="App">

    

      <Routes>

        {/*--------------------------------------------------------------*/}
        {/*------------------------RUTAS PRINCIPALES---------------------*/}
        {/*--------------------------------------------------------------*/}        
        <Route path='/' element={<Inicio/>}/>
        <Route path='/administrador' element={<Login_AdministradorP/>}/> 
        <Route path='/tutor' element={<Login_TutorP/>}/>


      

        {/*--------------------------------------------------------------*/}
        {/*------------------------RUTAS PREFECTO------------------------*/}
        {/*--------------------------------------------------------------*/}
        <Route element={<Rutas_Protegidas/> }>


        <Route path='/administrador/prefecto/reportes-alumno/:alumnoId' element={<Reportes_Alumno/>}></Route>
        <Route path='/administrador/prefecto/citatorios-alumno/:alumnoId' element={<Citatorios_Alumno/>}></Route>
        <Route path='/administrador/prefecto/faltas-alumno/:alumnoId' element={<Faltas_Alumno/>}></Route>
        <Route path='/administrador/prefecto/retardos-alumno/:alumnoId' element={<Retardos_Alumno/>}></Route>
        <Route path='/administrador/prefecto/justificaciones-alumno/:alumnoId' element={<Justificaciones_Alumno/>}></Route>
        <Route path='/administrador/prefecto/tareas-alumno/:alumnoId' element={<Tareas_Alumno/>}></Route>


          <Route path='/administrador/prefecto' element={<Prefecto/>}></Route>
          <Route path='/administrador/prefecto/agregar-reporte' element={<Agregar_Reporte/>}></Route>
          <Route path='/administrador/prefecto/agregar-citatorio' element={<Agregar_Citatorio/>}></Route>
          <Route path='/administrador/prefecto/agregar-falta' element={<Agregar_Falta/>}></Route>
          <Route path='/administrador/prefecto/agregar-retardo' element={<Agregar_Retardo/>}></Route>
          <Route path='/administrador/prefecto/agregar-justificacion' element={<Agregar_Justificacion/>}></Route>
          
          <Route path='/administrador/prefecto/agregar-tarea-atrasada' element={<Agregar_Tarea/>}></Route>
          <Route path='/administrador/prefecto/cerrar-sesion' element={<Cerrar_Sesion/>}></Route>

          <Route path='/administrador/prefecto/reportes' element={<Reportes/>}></Route>
          <Route path="/administrador/prefecto/reportes/:reporteId" element={<Ver_Reporte/>}/>

          <Route path='/administrador/prefecto/citatorios' element={<Citatorios/>}></Route>
          <Route path="/administrador/prefecto/citatorios/:citatorioId" element={<Ver_Citatorio/>}/>

          <Route path='/administrador/prefecto/faltas' element={<Faltas/>}></Route>
          <Route path="/administrador/prefecto/faltas/:faltaId" element={<Ver_Falta/>}/>

          <Route path='/administrador/prefecto/retardos' element={<Retardos/>}></Route>
          <Route path="/administrador/prefecto/retardos/:retardoId" element={<Ver_Retardo/>}/>

          <Route path='/administrador/prefecto/justificaciones' element={<Justificaciones/>}></Route>
          <Route path="/administrador/prefecto/justificaciones/:justificacionId" element={<Ver_Justificacion/>}/>


          <Route path='/administrador/prefecto/tareas-atrasadas' element={<Tareas_Atrasadas/>}></Route>
          <Route path="/administrador/prefecto/tareas-atrasadas/:tareaId" element={<Ver_Tarea_Atrasada/>}/>
        </Route>

        


        {/*--------------------------------------------------------------*/}
        {/*------------------------RUTAS ORIENTADORA---------------------*/}
        {/*--------------------------------------------------------------*/}
        <Route element={<Rutas_ProtegidasO/> }>
          <Route path='/administrador/orientadora' element={<Orientadora/>}></Route>
          <Route path='/administrador/orientadora/fais-alumno/:alumnoId' element={<Fais_Alumno/>}></Route>
          <Route path='/administrador/orientadora/fvds-alumno/:alumnoId' element={<Fvds_Alumno/>}></Route>

          <Route path='/administrador/orientadora/agregar-fai' element={<Agregar_FAI/>}></Route>
          <Route path='/administrador/orientadora/agregar-fvd' element={<Agregar_FVD/>}></Route>


          <Route path='/administrador/orientadora/alumnos' element={<AlumnosP/>}></Route>
          <Route path='/administrador/orientadora/alumnos/:alumnoId' element={<Ver_Alumno/>}></Route>
          <Route path='/administrador/orientadora/alumnos/nuevo' element={<Agregar_Alumno/>}></Route>

          <Route path='/administrador/orientadora/fais' element={<Fais/>}></Route>
          <Route path="/administrador/orientadora/fais/:faiId" element={<Ver_Fai/>}/>

          <Route path='/administrador/orientadora/fvds' element={<Fvds/>}></Route>
          <Route path="/administrador/orientadora/fvds/:fvdId" element={<Ver_Fvd/>}/>


          <Route path='/administrador/orientadora/tutores' element={<TutoresP/>}></Route>
          <Route path="/administrador/orientadora/tutores/:tutorId" element={<Ver_Tutor/>}/>

          <Route path='/administrador/orientadora/tutores/nuevo' element={<Agregar_Tutor/>}></Route>
          </Route>

        
        



        {/*--------------------------------------------------------------*/}
        {/*------------------------RUTAS DIRECTORA-----------------------*/}
        {/*--------------------------------------------------------------*/}
        <Route element={<Rutas_ProtegidasD/> }>
          <Route path='/administrador/directora' element={<Directora/>}></Route>
          <Route path='/administrador/directora/contrasenas' element={<Directora/>}></Route>

          <Route path='/administrador/directora/usuarios' element={<UsuariosP/>}></Route>
          <Route path="/administrador/directora/usuarios/:usuarioId" element={<Usuarios_Lista/>}/>

          <Route path='/administrador/directora/usuarios/nuevo' element={<Agregar_Usuario/>}></Route>
        </Route>


        {/*--------------------------------------------------------------*/}
        {/*--------------------------RUTAS TUTOR-------------------------*/}
        {/*--------------------------------------------------------------*/}
        <Route element={<Rutas_ProtegidasT/> }>
          <Route path='/tutor/inicio' element={<Tutor/>}></Route>
          <Route path="/tutor/inicio/reporte/:alumnoId" element={<Ver_ReporteTutor/>}/>
          <Route path="/tutor/inicio/reporte-ver/:reporteId" element={<Ver_ReporteT/>}/>

          <Route path="/tutor/inicio/citatorio/:alumnoId" element={<Ver_CitatorioTutor/>}/>
          <Route path="/tutor/inicio/citatorio-ver/:citatorioId" element={<Ver_CitatorioT/>}/>

          <Route path="/tutor/inicio/falta/:alumnoId" element={<Ver_FaltaTutor/>}/>
          <Route path="/tutor/inicio/falta-ver/:faltaId" element={<Ver_FaltaT/>}/>


          <Route path="/tutor/inicio/retardo/:alumnoId" element={<Ver_RetardoTutor/>}/>
          <Route path="/tutor/inicio/retardo-ver/:retardoId" element={<Ver_RetardoT/>}/>
          
          <Route path="/tutor/inicio/justificacion/:alumnoId" element={<Ver_JustificacionTutor/>}/>
          <Route path="/tutor/inicio/justificacion-ver/:justificacionId" element={<Ver_JustificacionT/>}/>

          <Route path="/tutor/inicio/tarea/:alumnoId" element={<Ver_TareaTutor/>}/>
          <Route path="/tutor/inicio/tarea-ver/:tareaId" element={<Ver_TareaT/>}/>
          
        </Route>

       

        {/*--------------------------------------------------------------*/}
        {/*--------------------------RUTAS 404---------------------------*/}
        {/*--------------------------------------------------------------*/}  
        <Route path='*' element={<div>NO EXISTE</div>}/>
         

      </Routes>
      
      {/*<Prefecto/>*/}
    </div>
  )
}

export default App;