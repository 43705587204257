import Cabecera from '../components/cabecera'
import Tarea from '../components/tarea'
import Alerta_EliminarT from "../alerts/alertaEliminarT"
import { useState } from "react"

const Tareas_Atrasadas = () => {
    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <Cabecera/>
        <Tarea alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarT alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default Tareas_Atrasadas