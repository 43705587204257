import Cabecera from "../components/cabecera"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Justificacion from "../components/formulario-justificacion"


const Agregar_Justificacion = () => {
    return(
        <>
            <Cabecera/>
            <Datos_Alumno/>
            <Formulario_Justificacion/>
            
        </>    
    )
}
export default Agregar_Justificacion