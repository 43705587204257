import './detalles-justificacion.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Justificacion = () => {
    const { justificacionId } = useParams()
    
    const [justificacion, setJustificacion] = useState ([]);
    const [alumno, setAlumno] = useState([]);
    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    const idAlJustificacion = localStorage.getItem("idAlJustificacion")

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }

    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/justificaciones/${justificacionId}`)
                .then(res => setJustificacion(res.data)); 

            
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${idAlJustificacion}`)
                .then(res => { setAlumno(res.data)
                    
                }); 
                
    }, [justificacionId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        switch (cargoNumero) {
            case 1:
                setGrupo('1');
                setSemestre('A')
                break;
            case 2:
                setGrupo('1');
                setSemestre('B')
                break;
            case 3:
                setGrupo('2');
                setSemestre('A')
                break;
            // Agrega más casos según sea necesario
            case 4:
                setGrupo('2');
                setSemestre('B')
                break;
            case 5:
                setGrupo('3');
                setSemestre('A')
            break;
            case 6:
                setGrupo('3');
                setSemestre('B')
                break;
            case 7:
                setGrupo('4');
                setSemestre('A')
                break;
            case 8:
                setGrupo('4');
                setSemestre('B')
                break;
                // Agrega más casos según sea necesario
            case 9:
                setGrupo('5');
                setSemestre('A')
                break;
            case 10:
                setGrupo('5');
                setSemestre('B')
                break;
            case 11:
                setGrupo('6');
                setSemestre('A')
                break;
            case 12:
                setGrupo('6');
                setSemestre('B')
                break;
            // ... y así sucesivamente hasta 12 casos
            default:
                
                break;
        }
    }, [alumno.Grupo_Semestres_id]);

    return(
        <>
        {justificacion && alumno &&( 
            <>

        <section className='detalles-justificacion'>
            <div className='detalles-justificacion-contenedor'>
                <div className='detalles-justificacion-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    <h4>{justificacion.fecha}</h4>
                    <h4>JUSTIFICACION {justificacion.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-justificacion-datos-alumno'>
                           
                        <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                        <div>
                            <span>GRADO: {grupo}</span>
                            <span>GRUPO: {semestre}</span>
                        </div>

                        <span>TURNO: MATUTINO</span>
                
                </div>

                <hr></hr>
                <div className='detalles-justificacion-datos-justificacion'>
                    <div>
                        <p><span>FECHAS JUSTIFICADAS: </span>DE: {justificacion.fecha} A: {justificacion.fecha_fin}</p>  
                    </div>

                    <div>
                        <p><span>MOTIVO: </span>{justificacion.motivo}</p>
                    </div>

                    <div>
                        <p><span>JUSTIFICANTE: </span>{justificacion.justificante}</p>
                    </div>
 
                    
                </div>

                <button onClick={atras} className='detalles-justificacion-eliminar'>ACEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Justificacion