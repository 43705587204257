import Cabecera from '../components/cabecera'
import Alerta_REP from "../alerts/alertaREP"
import Reporte_Alumno from '../consultas/reporte'


import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'

const Reportes_Alumno = () => {
    const { alumnoId } = useParams();

    const [alerta, setAlerta ] = useState (false)
    const [reportes, setReporte ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarReporte", {Alumnos_id: alumnoId})
            .then(res => setReporte(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <Cabecera/>
        <Reporte_Alumno alerta={alerta} setAlerta={setAlerta} reportes={reportes} alumno={alumno}/>
        <Alerta_REP alerta={alerta} setAlerta={setAlerta} reportes={reportes}/>
        
        </>
    )
}

export default Reportes_Alumno