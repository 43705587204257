import Cabecera from '../components/cabecera'
import Falta_Alumno from '../consultas/falta'
import Alerta_FAL from "../alerts/alertaFAL"

import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'

const Faltas_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [faltas, setFaltas ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarFalta", {Alumnos_id: alumnoId})
            .then(res => setFaltas(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <Cabecera/>
        <Falta_Alumno alerta={alerta} setAlerta={setAlerta} faltas={faltas} alumno={alumno}/>
        <Alerta_FAL alerta={alerta} setAlerta={setAlerta} faltas={faltas}/>
        
        </>
    )
}

export default Faltas_Alumno