import './tutores.css'


import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Tutores = () => {
    const [tutores, setTutores] = useState ([]);


    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/tutores/")
        .then((res) => setTutores(res.data.sort((a, b) => b.id - a.id)));

    }, [] )


    return(
        <>
        {tutores.map((tutor) => {

    return(
        <section key={tutor.id} className='tutores'>
            <div className='tutores-contenedor'>
                <span>TUTOR {tutor.id}</span>

                <span>{tutor.nombre} {tutor.apellidoP} {tutor.apellidoM}</span>

                <div className='tutores-botones'>
                <Link className="link-tutor" to={`/administrador/orientadora/tutores/${tutor.id}`}>DETALLES</Link>
                    {/*<button>ELIMINAR</button>*/}
                </div>
            </div>
        </section>
        )
    })}
</>

    )
}

export default Tutores