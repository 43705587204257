import './justificacion.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"


const Justificacion = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [justificaciones, setJustificaciones] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/justificaciones/")
        .then((res) => setJustificaciones(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    return(
        <>
        {justificaciones.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        justificaciones.map((justificacion) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === justificacion.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlJustificacion', JSON.stringify(justificacion.Alumnos_id))
          };

          //////
          const mandarJustificacion = () => {
            localStorage.setItem('idJustificacion', JSON.stringify(justificacion.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarJustificacion()
          }
            return(

        <section key={justificacion.id} className='justificacion'>
            <div className='justificacion-contenedor'>
                <span>JUSTIFICACION {justificacion.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='justificacion-botones'>
                <Link onClick={mandarAlumno} className="link-justificacion" to={`/administrador/prefecto/justificaciones/${justificacion.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
        )
    })
    )}
  </>
);
};

export default Justificacion