import './formulario-falta.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'

const Formulario_Falta= () => {

    const {register, handleSubmit} = useForm()
    const navigate = useNavigate ()

    const id = localStorage.getItem('id');

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/faltas/', data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            console.log('Solicitud POST exitosa', response);
            navigate('/administrador/prefecto/')
            console.log(data)
             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud POST', error);
            });
        }

    return(
        <section className='formulario-falta-contenedor'>
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className='formulario-falta-formulario'>
                <label>FECHA</label>
                <input type='date' {...register ('fecha', {
                    required: true
                })}></input>

                <label>FECHA LÍMITE PARA JUSTIFICAR</label>
                <input type='date' {...register ('fecha_limite_justificar', {
                    required: true
                })}></input>

                {/*<label>ESTADO</label>*/}
                <input type='hidden' defaultValue={0} {...register ('estado', {
                    required: true
                })}></input>

                {/*<label>RESULTADOS</label>*/}
                <input type='hidden' defaultValue={id} {...register ('Alumnos_id', {
                    required: true
                })}></input>

                <button>AGREGAR FALTA</button>
            </form>

        </section>

    )
}

export default Formulario_Falta