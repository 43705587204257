import './menu-horizontal.css'
import Icon_administrador from '../assets/icon_administrador.png'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_cerrar from '../assets/icon_cerrar.png'
import Icon_salir from '../assets/icon_salir.png'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Menu_horizontalD = ({ menu, setMenu}) =>{
           
            {/*const [menu, setMenu ] = useState (false)*/}


            const mostrarMenu = () => {
                setMenu( !menu)
            }

            const navigate = useNavigate()

            //para eliminar el localstorage
            const logOut = () => {
                localStorage.removeItem("auth")
                navigate('/')
            }

           
    return(
        <section className={`menu-horizontal-contenedor ${ menu ? 'estaActivo' : ''}`}>

           

            <div className='menu-horizontal-contenedor-administrador'>
                <img src={Icon_administrador}></img>

                <h3>M.E. NEIMA CAHUICH</h3>

                <h4>DIRECTORA</h4>

                <h4 onClick={logOut} className='menu-horizontal-lista1'>
                        <img src={Icon_salir}></img>
                        <span>CERRAR SESIÓN</span>
                </h4>
            </div>

            <hr className='menu-horizontal-separador'></hr>

            <div className='menu-horizontal-cerrar'> 
                <h3>MENÚ</h3>
                <img src={Icon_cerrar}
                    onClick={mostrarMenu}></img>
            </div>

            <nav className='menu-horizontal-contenedor-nav'>
                <ul className='menu-horizontal-contenedor-lista'>
                    <Link to='/administrador/directora/usuarios' className='menu-horizontal-lista'>
                        <img src={Icon_reporte}></img>
                        <span>USUARIOS</span>
                        
                    </Link>

                    <Link to='/administrador/directora' className='menu-horizontal-lista'>
                        <img src={Icon_reporte}></img>
                        <span>CONTRASEÑAS</span>
                    </Link>

                    
                </ul>
            </nav>

        </section>
        
    )
}

export default Menu_horizontalD