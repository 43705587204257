import CabeceraT from '../components/cabeceraT'
import Falta_AlumnoT from '../consultas/faltaT'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Ver_FaltaTutor = () => {

    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [faltas, setFaltas ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarFalta", {Alumnos_id: alumnoId})
            .then(res => setFaltas(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )


    return(
        <>
        <CabeceraT/>
        <h2>FALTAS</h2>
        <Falta_AlumnoT alumno={alumno} faltas={faltas}/>  
        
        </>
    )
}

export default Ver_FaltaTutor