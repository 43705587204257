import './tarea.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Tarea = ( {alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [tareas, setTareas] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/tareas/")
        .then((res) => setTareas(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    return(
        <>{tareas.length === 0 ? ( // Verifica si el array de justificaciones está vacío
        <div className='no-registros'>
          <p>NO SE ENCONTRARON REGISTROS</p>
        </div>
      ) : (
        tareas.map((tarea) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === tarea.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlTarea', JSON.stringify(tarea.Alumnos_id))
            localStorage.setItem('idAlTareaMateria', JSON.stringify(tarea.Materias_id))
          };

          //////
          const mandarTarea = () => {
            localStorage.setItem('idTarea', JSON.stringify(tarea.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarTarea()
          }
            return(
        <section key={tarea.id} className='tarea'>
            <div className='tarea-contenedor'>
                <span>TAREA {tarea.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='tarea-botones'>
                <Link onClick={mandarAlumno} className="link-tarea" to={`/administrador/prefecto/tareas-atrasadas/${tarea.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
        )
    })
    )}
  </>
);
};

export default Tarea