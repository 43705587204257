import './formulario-tutor.css'
import { useForm } from 'react-hook-form' 
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Formulario_Tutor = () => {
    const navigate = useNavigate ()

    const {register, handleSubmit} = useForm();

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/tutores/', data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            console.log('Solicitud POST exitosa', response);
            navigate('/administrador/orientadora/alumnos/nuevo')
             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud POST', error);
            });
    }



    return(
        <section className='formulario-tutor-contenedor'>
            <form onSubmit={handleSubmit( onSubmit)}
                className='formulario-tutor-formulario'>

                <label>NOMBRE</label>
                <input type='text' {...register('nombre', {
                    required: true
                })}></input>

                <label>APELLIDO PATERNO</label>
                <input type='text' {...register('apellidoP', {
                    required: true
                })}></input>

                <label>APELLIDO MATERNO</label>
                <input type='text' {...register('apellidoM', {
                    required: true
                })}></input>

                <label>TELEFONO</label>
                <input type='text' {...register('telefono', {
                    required: true
                })}></input>

                <label>COMUNIDAD</label>
                <input type='text' {...register('comunidad', {
                    required: true
                })}></input>

                <label>USUARIO</label>
                <input type='text' {...register('usuario', {
                    required: true
                })}></input>

                <label>CONTRASEÑA</label>
                <input type='text' {...register('contraseña', {
                    required: true
                })}></input>

                <button>AGREGAR TUTOR</button>
            </form>

        </section>

    )
}

export default Formulario_Tutor