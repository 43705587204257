import '../alerts/alerta.css'

const Alerta_Sin_Tutor = ({alerta, setAlerta }) => {

    const mostrarAlerta = () =>{
        setAlerta(!alerta)
    }

    return(
        <section className={`alerta-seccion ${ alerta ? 'mostrar' : ''}`}>
            <div className='alerta-contenedor'>
                <div className='alerta-cabecera'>
                    <h3>ERROR</h3>

                </div >
                <div className='alerta-cuerpo'>
                    <h4>NO SE HA ENCONTRADO TUTOR, INTENTE CON OTRO NOMBRE</h4>
                    <button onClick={mostrarAlerta}>ACEPTAR</button>
                </div>
            </div>    
        </section>
    )
}

export default Alerta_Sin_Tutor