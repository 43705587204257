import CabeceraT from '../components/cabeceraT'
import Tarea_AlumnoT from '../consultas/tareaT'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Ver_TareaTutor = () => {

    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [tareas, setTareas ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarTarea", {Alumnos_id: alumnoId})
            .then(res => setTareas(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        
        <CabeceraT/> 
        <h2>TAREAS</h2>
        <Tarea_AlumnoT alumno={alumno} tareas={tareas}/>
        
        </>
    )
}

export default Ver_TareaTutor