import Cabecera from '../components/cabecera'
import AlertaTAR from "../alerts/alertaTAR"
import Tarea_Alumno from '../consultas/tarea'

import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Tareas_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [tareas, setTareas ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarTarea", {Alumnos_id: alumnoId})
            .then(res => setTareas(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <Cabecera/>
        <Tarea_Alumno alerta={alerta} setAlerta={setAlerta} tareas={tareas} alumno={alumno}/>
        <AlertaTAR alerta={alerta} setAlerta={setAlerta} tareas={tareas}/>
        
        </>
    )
}

export default Tareas_Alumno