import CabeceraT from '../components/cabeceraT'
import Justificacion_AlumnoT from '../consultas/justificacionT'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Ver_JustificacionTutor = () => {

    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [justificaciones, setJustificaciones ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarJustificacion", {Alumnos_id: alumnoId})
            .then(res => setJustificaciones(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <CabeceraT/> 
        <h2>JustificacionS</h2>
        <Justificacion_AlumnoT alumno={alumno} justificaciones={justificaciones}/>
        
        </>
    )
}

export default Ver_JustificacionTutor