import './menu-horizontal.css'
import Icon_administrador from '../assets/icon_administrador.png'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_citatorio from '../assets/icon_citatorio.png'
import Icon_falta from '../assets/icon_falta.png'
import Icon_retardo from '../assets/icon_retardo.png'
import Icon_justificacion from '../assets/icon_justificacion.png'
import Icon_tarea from '../assets/icon_tarea.png'
import Icon_cerrar from '../assets/icon_cerrar.png'
import Icon_salir from '../assets/icon_salir.png'

import { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom'

const Menu_horizontal = ({ menu, setMenu}) =>{
           
            {/*const [menu, setMenu ] = useState (false)*/}


            const mostrarMenu = () => {
                setMenu( !menu)
            }

            const navigate = useNavigate()

            //para eliminar el localstorage
            const logOut = () => {
                localStorage.removeItem("auth")
                localStorage.removeItem("cargo")
                navigate('/')
            }

           
    return(
        <section className={`menu-horizontal-contenedor ${ menu ? 'estaActivo' : ''}`}>

           

            <div className='menu-horizontal-contenedor-administrador'>
                <img src={Icon_administrador}></img>

                <h3> BR. LUIS</h3>

                <h4>PREFECTO</h4>

                <h4 onClick={logOut} className='menu-horizontal-lista1'>
                        <img src={Icon_salir}></img>
                        <span>CERRAR SESIÓN</span>
                </h4>
            </div>

            <hr className='menu-horizontal-separador'></hr>

            <div className='menu-horizontal-cerrar'> 
                <h3>MENÚ</h3>
                <img src={Icon_cerrar}
                    onClick={mostrarMenu}></img>
            </div>

            <nav className='menu-horizontal-contenedor-nav'>
                <ul className='menu-horizontal-contenedor-lista'>

                    <Link to='/administrador/prefecto/reportes' className='menu-horizontal-lista'>
                        <img src={Icon_reporte}></img>
                        <span>REPORTES</span>
                        
                    </Link>

                    <Link to='/administrador/prefecto/citatorios' className='menu-horizontal-lista'>
                        <img src={Icon_citatorio}></img>
                        <span>CITATORIOS</span>
                    </Link>

                    <Link to='/administrador/prefecto/faltas' className='menu-horizontal-lista'>
                    <img src={Icon_falta}></img>
                        <span>FALTAS</span>
                    </Link>

                    <Link to='/administrador/prefecto/retardos' className='menu-horizontal-lista'>
                        <img src={Icon_retardo}></img>
                        <span>RETARDOS</span>
                    </Link>

                    <Link to='/administrador/prefecto/justificaciones' className='menu-horizontal-lista'>
                        <img src={Icon_justificacion}></img>
                        <span>JUSTIFICACIONES</span>
                    </Link >

                    <Link to='/administrador/prefecto/tareas-atrasadas' className='menu-horizontal-lista'>
                        <img src={Icon_tarea}></img>
                        <span>TAREAS ATRASADAS</span>
                    </Link>

                    
                    
                </ul>
            </nav>

        </section>
        
    )
}

export default Menu_horizontal