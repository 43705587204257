import './contrasenas.css'

import { useForm } from 'react-hook-form' 
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Contrasenas = ({tutor, menu, setMenu} ) => {
    const tutorId = (tutor.id)

    const {register, handleSubmit, setValue} = useForm();
    const navigate = useNavigate ()

    
    useEffect(() => {
        // Establecer el valor por defecto después de la renderización inicial
        setValue('Tutores_id', tutorId);
      }, [tutorId]);

    const onSubmit = (data) => {
        Axios.put(`https://plantel-candelaria.onrender.com/tutores/${tutorId}`, data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            window.location.reload();

             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud PUT', error);
            });

    }

    return(
        <section className={`contrasenas-contenedor ${ menu ? 'estaActivo' : ''}`}>
            
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className='contrasenas-formulario'>

                {/*<label>TUTOR ID</label>
                <input type='hidden' defaultValue={tutorId} {...register ('id', {
                    required: true
                })}></input>*/}

                <label>NUEVA CONTRASEÑA</label>
                <input type='password' {...register ('contraseña', {
                    required: true
                })}></input>

                <button>CAMBIAR CONTRASEÑA</button>
            </form>


        </section>
    )
}

export default Contrasenas