import { Link } from 'react-router-dom'
import './citatorio.css'

import { useState, useEffect } from "react"
import Axios from "axios"
import Alerta_EliminarC from '../alerts/alertaEliminarC'

const Citatorio = ( {alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [citatorios, setCitatorios] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/citatorios/")
        .then((res) => setCitatorios(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    return(

        <>
        {citatorios.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        citatorios.map((citatorio) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === citatorio.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlCitatorio', JSON.stringify(citatorio.Alumnos_id))
          };

          const mandarCitatorio = () => {
            localStorage.setItem('idCitatorio', JSON.stringify(citatorio.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarCitatorio()
          }
            return(

        <section key={citatorio.id} className='citatorio'>
            <div className='citatorio-contenedor'>
                <span>CITATORIO {citatorio.id} </span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='citatorio-botones'>
                <Link onClick={mandarAlumno} className="link-citatorio" to={`/administrador/prefecto/citatorios/${citatorio.id}`}>DETALLES</Link>
                <button onClick= { mandarDatos }>ELIMINAR</button>
                </div>
            </div>
            <Alerta_EliminarC/>
        </section>

    )
})
)}
</>
);
};

export default Citatorio