import { useState } from "react"
import Buscador_Tutor from "../components/burcador-tutor"
import CabeceraO from "../components/cabeceraO"
import Datos_Tutor from "../components/datos-tutor"
import Formulario_Alumno from "../components/formulario-alumno"
import Alerta_Sin_Tutor from '../alerts/alertaSinTutor'



const Agregar_Alumno = () => {
    const [tutor, setTutor] = useState ([]);
    const [menu, setMenu] = useState (true)
    const [alerta, setAlerta] = useState (false)

    return(
        <>
            <CabeceraO/>
            <Buscador_Tutor tutor={tutor} setTutor={setTutor} menu={menu} setMenu={setMenu} alerta={alerta} setAlerta={setAlerta} />
            <Datos_Tutor tutor={tutor}/>            
            <Formulario_Alumno tutor={tutor} menu={menu} setMenu={setMenu}/>
            <Alerta_Sin_Tutor alerta={alerta} setAlerta={setAlerta} />
        </>    
    )
}
export default Agregar_Alumno