import '../alerts/alerta.css'
import Axios from "axios"
import { useNavigate } from 'react-router-dom'

const Alerta_EliminarC = ({alerta, setAlerta}) => {

    const navigate = useNavigate();

    const mostrarAlerta = () =>{
        setAlerta(!alerta)
    }

    const idCitatorio = localStorage.getItem("idCitatorio")

    const deleteCitatorio = async (idCitatorio) => {
        await Axios.delete(`https://plantel-candelaria.onrender.com/citatorios/${idCitatorio}`)
        
        window.location.reload();
        //getCitatorios()
        /*Axios.get("http://localhost:8000/reportes/")
            .then(res => setReportes(res.data))*/
    }

    const eliminarDatos = ( )=>{
        mostrarAlerta()
        deleteCitatorio(idCitatorio)
      }

    return(
        <section className={`alerta-seccion ${ alerta ? 'mostrar' : ''}`}>
            <div className='alerta-contenedor'>
                <div className='alerta-cabecera'>
                    <h3>ELIMINAR</h3>

                </div >
                <div className='alerta-cuerpo'>
                    <h4>¿SEGURO QUE DESEA ELIMINAR ESTE REGISTRO?</h4>
                    <button onClick={eliminarDatos}>ACEPTAR</button>
                    <button onClick={mostrarAlerta}>NO</button>
                </div>
            </div>    
        </section>
    )
}

export default Alerta_EliminarC