import Cabecera from '../components/cabecera'
import Detalles_Retardo from '../components/detalles-retardo'


const Ver_Retardo = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Retardo/>
        </>
    )
}

export default Ver_Retardo