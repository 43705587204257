import './datos-tutor.css'

const Datos_Tutor = ({tutor} ) => {
    return(
        <>
        { tutor &&(
            <>
        <section className='datos-tutor'>
            <div className='datos-tutor-contenedor'>            
                <span>{tutor.nombre} {tutor.apellidoP} {tutor.apellidoM} </span>

                <div><span>{tutor.telefono} </span></div>

                {/*<div>
                    <span>GRADO: 1</span>
                    <span>GRUPO: B</span>
                </div>*/}

                <span>COMUNIDAD: {tutor.comunidad} </span>
            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Datos_Tutor