import './detalles-tarea.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Tarea = () => {
    const { tareaId } = useParams()
    
    const [tarea, setTarea] = useState ([]);
    const [alumno, setAlumno] = useState([]);
    const [materia, setMateria] = useState([]);
    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    const idAlTarea = localStorage.getItem("idAlTarea")
    const idAlTareaMateria = localStorage.getItem("idAlTareaMateria")

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }

    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/tareas/${tareaId}`)
                .then(res => setTarea(res.data)); 

            
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${idAlTarea}`)
                .then(res => { setAlumno(res.data) }); 

            Axios.get(`https://plantel-candelaria.onrender.com/materias/${idAlTareaMateria}`)
                .then(res => { setMateria(res.data) });
                
    }, [tareaId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        switch (cargoNumero) {
            case 1:
                setGrupo('1');
                setSemestre('A')
                break;
            case 2:
                setGrupo('1');
                setSemestre('B')
                break;
            case 3:
                setGrupo('2');
                setSemestre('A')
                break;
            // Agrega más casos según sea necesario
            case 4:
                setGrupo('2');
                setSemestre('B')
                break;
            case 5:
                setGrupo('3');
                setSemestre('A')
            break;
            case 6:
                setGrupo('3');
                setSemestre('B')
                break;
            case 7:
                setGrupo('4');
                setSemestre('A')
                break;
            case 8:
                setGrupo('4');
                setSemestre('B')
                break;
                // Agrega más casos según sea necesario
            case 9:
                setGrupo('5');
                setSemestre('A')
                break;
            case 10:
                setGrupo('5');
                setSemestre('B')
                break;
            case 11:
                setGrupo('6');
                setSemestre('A')
                break;
            case 12:
                setGrupo('6');
                setSemestre('B')
                break;
            // ... y así sucesivamente hasta 12 casos
            default:
                
                break;
        }
    }, [alumno.Grupo_Semestres_id]);



    return(
        <>
        {tarea && alumno && materia &&( 
            <>

        <section className='detalles-tarea'>
            <div className='detalles-tarea-contenedor'>
                <div className='detalles-tarea-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    <h4>{tarea.fecha}</h4>
                    <h4>TAREA ATRASADA {tarea.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-tarea-datos-alumno'>
                           
                        <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM} </span>

                        <div>
                            <span>GRADO: {grupo} </span>
                            <span>GRUPO:  {semestre}</span>
                        </div>

                        <span>TURNO: MATUTINO</span>
                
                </div>

                <hr></hr>
                <div className='detalles-tarea-datos-tarea'>
                    <div>
                        <p><span>FECHAS LÍMITE DE RECEPCIÓN: </span>{tarea.fecha_limite_recepcion}</p>  
                    </div>

                    <div>
                        <p><span>MATERIA: </span>{materia.materia}</p>
                    </div>
 
                    
                </div>

                <button onClick={atras} className='detalles-tarea-eliminar'>ACEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Tarea