import Cabecera from '../components/cabecera'
import Detalles_Justificacion from '../components/detalles-justificacion'


const Ver_Justificacion = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Justificacion/>
        </>
    )
}

export default Ver_Justificacion