import CabeceraO from '../components/cabeceraO'
import Fai from '../components/fai'
import Alerta_Fvd from "../alerts/alertaFVD"
import { useState, useEffect } from "react"
import Fvd_Alumno from '../consultas/fvd'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'

const Fvds_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [fvds, setFvds ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarFvd", {Alumnos_id: alumnoId})
            .then(res => setFvds(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <CabeceraO/>
        <Fvd_Alumno alerta={alerta} setAlerta={setAlerta} fvds={fvds} alumno={alumno}/>
        <Alerta_Fvd alerta={alerta} setAlerta={setAlerta} fvds={fvds}/>
        
        </>
    )
}

export default Fvds_Alumno