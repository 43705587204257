import '../alerts/alerta.css'

const Alerta = ({alerta, setAlerta }) => {

    const mostrarAlerta = () =>{
        setAlerta(!alerta)
    }

    return(
        <section className={`alerta-seccion ${ alerta ? 'mostrar' : ''}`}>
            <div className='alerta-contenedor'>
                <div className='alerta-cabecera'>
                    <h3>ERROR</h3>

                </div >
                <div className='alerta-cuerpo'>
                    <h4>USUARIO Y/O CONTRASEÑA INCORRECTA</h4>
                    <button onClick={mostrarAlerta}>ACEPTAR</button>
                </div>
            </div>    
        </section>
    )
}

export default Alerta