import PresentacionP from "../components/presentacionP"
import Login_Administrador from "../components/login-administrador"
import { Route, Routes } from "react-router-dom"
import Prefecto from "./prefecto"
import Alerta from "../alerts/alerta"
import { useState } from "react"


const Login_AdministradorP = () => {

    const [alerta, setAlerta ] = useState (false)


            /*const mostrarAlerta = () => {
                setAlerta( !alerta)
            }*/

    return(
        <>
        <PresentacionP/>
        <Login_Administrador alerta={alerta} setAlerta={setAlerta}/>
        <Alerta alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default Login_AdministradorP