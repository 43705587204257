import Login_Tutor from "../components/login-tutor"
import PresentacionP from "../components/presentacionP"

const Login_TutorP = () => {
    return(
        <>
        <PresentacionP/>
        <Login_Tutor/>
        </>
        
    )
}

export default Login_TutorP