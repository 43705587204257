import CabeceraT from '../components/cabeceraT'
import Retardo_AlumnoT from '../consultas/retardoT'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Ver_RetardoTutor = () => {

    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [retardos, setFaltas ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarRetardo", {Alumnos_id: alumnoId})
            .then(res => setFaltas(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>

        <CabeceraT/> 
        <h2>RETARDOS</h2>
        <Retardo_AlumnoT alumno={alumno} retardos={retardos}/>
        
        </>
    )
}

export default Ver_RetardoTutor