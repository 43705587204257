import CabeceraT from '../components/cabeceraT'
import Detalles_Tutor from '../components/detalles-tutor'
import Citatorio_AlumnoT from '../consultas/citatorioT '

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Ver_CitatorioTutor = () => {

    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [citatorios, setCitatorios ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarCitatorio", {Alumnos_id: alumnoId})
            .then(res => setCitatorios(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <CabeceraT/>
        <h2>CITATORIOS</h2>
        <Citatorio_AlumnoT alumno={alumno} citatorios={citatorios}/> 
        
        </>
    )
}

export default Ver_CitatorioTutor