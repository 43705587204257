import './link-tutor.css'
import {Link} from 'react-router-dom'

const Link_Tutor = () => {

    
    return(
        <section className='link-tutor-contenedor'>
            <Link to='/administrador/orientadora/tutores/nuevo'
                className='link-tutor-boton'>
                + NUEVO TUTOR
            </Link>

        </section>
    )
}

export default Link_Tutor