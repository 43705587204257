import CabeceraO from '../components/cabeceraO'
import Detalles_Fvd from '../components/detalles-fvd'


const Ver_Fvd = () => {

    return(
        <>
        <CabeceraO/> 
        <Detalles_Fvd/>
        </>
    )
}

export default Ver_Fvd