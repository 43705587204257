import './detalles-reporte.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Reporte = () => {

    const { reporteId } = useParams()
    
    const [reporte, setReporte] = useState ([]);
    const [alumno, setAlumno] = useState([]);

    const idAlReporte = localStorage.getItem("idAlReporte")

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }
  
    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/reportes/${reporteId}`)
                .then(res => setReporte(res.data)); 

            
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${idAlReporte}`)
                .then(res => { setAlumno(res.data)
                    
                }); 
                
    }, [reporteId]);


    const [semestre, setSemestre] = useState()
    const [grupo, setGrupo] = useState()

    const alumnoGS = alumno.Grupo_Semestres_id;
   

    useEffect(() => {
        // Convierte los valores grupo y semestre correcto
        
        switch (alumnoGS) {
          case 1:
            setSemestre('1');
            setGrupo('A');
            break;
          case 2:
            setSemestre('1');
            setGrupo('B');
            break;
          case 3:
            setSemestre('2');
            setGrupo('A');
            break;
          case 4:
            setSemestre('2');
            setGrupo('B');
            break;
          case 5:
            setSemestre('3');
            setGrupo('A');
            break;
          case 6:
            setSemestre('3');
            setGrupo('B');
            break;
          case 7:
            setSemestre('4');
            setGrupo('A');
            break;
          case 8:
            setSemestre('4');
            setGrupo('B');
            break;
          case 9:
            setSemestre('5');
            setGrupo('A');
            break;
          case 10:
            setSemestre('5');
            setGrupo('B');
            break;
          case 11:
            setSemestre('6');
            setGrupo('A');
          case 12:
            setSemestre('6');
            setGrupo('B');
            break;
          // Puedes agregar más casos según sea necesario
          default:
            setSemestre('6');
            setGrupo('B');
            break;
        }
    }, []);



    return(
        <>
        {reporte && alumno &&( 
            <>

           

        <section className='detalles-reporte'>
            <div className='detalles-reporte-contenedor'>
                <div className='detalles-reeporte-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    <h4>{reporte.fecha}</h4>
                    <h4>REPORTE {reporte.id} </h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-reporte-datos-alumno'>
                           
                        <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM} </span>

                        <div>
                            <span>SEMESTRE: {semestre} </span>
                            <span>GRUPO: {grupo} </span>
                        </div>

                        <span>TURNO: MATUTINO</span>
                
                </div>

                <hr></hr>
                <div className='detalles-reporte-datos-reporte'>
                    <div>
                        <p><span>MOTIVO: </span> {reporte.motivo}</p>  
                    </div>

                    <div>
                        <p><span>SANCIÓN: </span>{reporte.sancion} </p>
                    </div>
                    
                </div>

                <button onClick={atras} className='detalles-reporte-eliminar'>ACEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Reporte