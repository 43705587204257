import './fai.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Fai = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [fais, setFais] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/fais/")
        .then((res) => setFais(res.data.sort((a, b) => b.id - a.id)));            //.then(res => setFais(res.data))


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    return(
        <>
        {fais.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        fais.map((fai) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === fai.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlFai', JSON.stringify(fai.Alumnos_id))
          };
          //////
          const mandarFai = () => {
            localStorage.setItem('idFai', JSON.stringify(fai.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarFai()
          }
    return(
        <section key={fai.id} className='fai'>
            <div className='fai-contenedor'>
                <span>ATENCION INDIVIDUAL {fai.id} </span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='fai-botones'>
                <Link onClick={mandarAlumno} className="link-fai" to={`/administrador/orientadora/fais/${fai.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
        )
    })
    )}
  </>
);
};

export default Fai