import './notificaciones-prefecto.css'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_citatorio from '../assets/icon_citatorio.png'
import Icon_falta from '../assets/icon_falta.png'
import Icon_retardo from '../assets/icon_retardo.png'
import Icon_justificacion from '../assets/icon_justificacion.png'
import Icon_tarea from '../assets/icon_tarea.png'
import Icon_reporteN from '../assets/icon_reporteN.png'
import Icon_citatorioN from '../assets/icon_citatorioN.png'
import Icon_faltaN from '../assets/icon_faltaN.png'
import Icon_retardoN from '../assets/icon_retardoN.png'
import Icon_justificacionN from '../assets/icon_justificacionN.png'
import Icon_tareaN from '../assets/icon_tareaN.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'

const Notificaciones_tutor = ({alumno} ) => {

    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    


    
    return(
      <>
      {alumno.map ((alumno => {
  


        return(



        <section key={alumno.id} className='notificaciones-prefecto'>

            <div className='notificaciones-prefecto-contenedor'>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR DATOS ALUMNO------------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-dato-alumno'>
                    <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                    <div>
                        <span>SEMESTRE:  </span>
                        <span>GRUPO:  </span>
                    </div>

                    <span>TURNO: {alumno.turno} </span>

                </div>

                <hr></hr>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR NOTIFICACIONES----------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-notificaciones'>
                <Link to={`/tutor/inicio/reporte/${alumno.id}`} className='link-img'><img src={Icon_reporte}></img></Link>
                <Link to={`/tutor/inicio/citatorio/${alumno.id}`} className='link-img'><img src={Icon_citatorio}></img></Link>
                <Link to={`/tutor/inicio/falta/${alumno.id}`} className='link-img'><img src={Icon_falta}></img></Link>
                <Link to={`/tutor/inicio/retardo/${alumno.id}`} className='link-img'><img src={Icon_retardo}></img></Link>
                <Link to={`/tutor/inicio/justificacion/${alumno.id}`} className='link-img'><img src={Icon_justificacion}></img></Link>
                <Link to={`/tutor/inicio/tarea/${alumno.id}`} className='link-img'><img src={Icon_tarea}></img></Link>

                </div>
            </div>
        </section>
        )

}) ) 
        
        }
                
        </>
    )
}

export default Notificaciones_tutor