import Cabecera from "../components/cabecera"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Tarea from "../components/formulario-tarea"

const Agregar_Tarea = () => {
    return(
        <>
            <Cabecera/>
            <Datos_Alumno/>
            <Formulario_Tarea/>
        </>    
    )
}
export default Agregar_Tarea