import Cabecera from "../components/cabecera"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Falta from "../components/formulario-falta"

const Agregar_Falta = () => {
    return(
        <>
            <Cabecera/>
            <Datos_Alumno/>
            <Formulario_Falta/>
        </>    
    )
}
export default Agregar_Falta