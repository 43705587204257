import Cabecera from '../components/cabecera'
import Detalles_Falta from '../components/detalles-falta'


const Ver_Falta = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Falta/>
        </>
    )
}

export default Ver_Falta