import CabeceraO from '../components/cabeceraO'
import Detalles_Fai from '../components/detalles-fai'


const Ver_Fai = () => {

    return(
        <>
        <CabeceraO/> 
        <Detalles_Fai/>
        </>
    )
}

export default Ver_Fai