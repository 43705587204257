import { Link } from 'react-router-dom'
import './reporte.css'
import Alerta_Eliminar from '../alerts/alertaEliminar'

import { useState, useEffect } from "react"
import Axios from "axios"

const Reporte = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [reportes, setReportes] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/reportes/")
        .then((res) => setReportes(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    /*const deleteReporte = async (id) => {
        await Axios.delete(`http://localhost:8000/reportes/${id}`)
        //getCitatorios()
        Axios.get("http://localhost:8000/reportes/")
            .then(res => setReportes(res.data))
    }*/


    return(
        <>
        {reportes.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        reportes.map((reporte) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === reporte.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlReporte', JSON.stringify(reporte.Alumnos_id))
          };

          //////
          const mandarReporte = () => {
            localStorage.setItem('idReporte', JSON.stringify(reporte.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarReporte()
          }
            return(

        <section key={reporte.id} className='reporte'>
            <div className='reporte-contenedor'>
                <span>REPORTE {reporte.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='reporte-botones'>
                    <Link onClick={mandarAlumno} className="link-reporte" to={`/administrador/prefecto/reportes/${reporte.id}`}>DETALLES</Link>
                    <button onClick= { mandarDatos }>ELIMINAR</button>
                </div>
            </div>
            
        </section>
        
            )
        })
        )}
      </>
    );
  };

export default Reporte