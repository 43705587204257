import CabeceraD from '../components/cabeceraD'
import Link_Usuario from '../components/link-usuario'
import Usuarios from '../components/usuarios'
import Alerta_EliminarUsuario from '../alerts/alertaEliminarUsuario'
import { useState } from 'react'

const UsuariosP = () => {

    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <CabeceraD/>
        <Link_Usuario/>
        <Usuarios alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarUsuario alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default UsuariosP