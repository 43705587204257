import Alerta_EliminarF from '../alerts/alertaEliminarF'
import Cabecera from '../components/cabecera'
import Falta from '../components/falta'

import { useState } from "react"

const Faltas = () => {
    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <Cabecera/>
        <Falta alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarF alerta={alerta} setAlerta={setAlerta}/>
        
        </>
    )
}

export default Faltas