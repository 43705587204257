import { Navigate, Outlet } from "react-router-dom"

const Rutas_ProtegidasT = ({
    redirectPath = '/'
}) => {
    const canActivate = localStorage.getItem("auth")


    if(canActivate){
       return <Outlet/>     
    } 

    return <Navigate to={redirectPath}/>
    
}

export default Rutas_ProtegidasT