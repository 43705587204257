import Alumnos from '../components/alumnos'
import CabeceraO from '../components/cabeceraO'
import Link_Alumno from '../components/link-alumno'

const AlumnosP = () => {
    return(
        <>
        <CabeceraO/>
        <Link_Alumno/>
        <Alumnos/>
        
        </>
    )
}

export default AlumnosP