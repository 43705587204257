import CabeceraT from '../components/cabeceraT.js'
import Detalles_Tarea from '../components/detalles-tarea.js'


const Ver_TareaT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Tarea/>
        </>
    )
}

export default Ver_TareaT