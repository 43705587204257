import CabeceraO from '../components/cabeceraO'
import Link_Tutor from '../components/link-tutor'
import Tutores from '../components/tutores'

const TutoresP = () => {
    return(
        <>
        <CabeceraO/>
        <Link_Tutor/>
        <Tutores/>
        </>
    )
}

export default TutoresP