import Cabecera from '../components/cabecera'
import AlertaRET from "../alerts/alertaRET"
import Retardo_Alumno from '../consultas/retardo'

import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'

const Fais_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [retardos, setRetardos ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarRetardo", {Alumnos_id: alumnoId})
            .then(res => setRetardos(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <Cabecera/>
        <Retardo_Alumno alerta={alerta} setAlerta={setAlerta} retardos={retardos} alumno={alumno}/>
        <AlertaRET alerta={alerta} setAlerta={setAlerta} retardos={retardos}/>
        
        </>
    )
}

export default Fais_Alumno