import Alerta_EliminarF from '../alerts/alertaEliminarC'
import Cabecera from '../components/cabecera'
import Citatorio from '../components/citatorio'
import { useState } from "react"


const Citatorios = () => {
    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <Cabecera/>
        <Citatorio  alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarF  alerta={alerta} setAlerta={setAlerta}/>
        
        </>
    )
}

export default Citatorios