import Cabecera from '../components/cabecera'
import Alerta_EliminarJ from "../alerts/alertaEliminarJ"
import Justificacion from '../components/justificacion'
import { useState } from "react"


const Justificaciones = () => {
    const [alerta, setAlerta ] = useState (false)
    return(
        <>
        <Cabecera/>
        <Justificacion alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarJ alerta={alerta} setAlerta={setAlerta}/>
       
        </>
    )
}

export default Justificaciones