import CabeceraO from "../components/cabeceraO"
import Alumno from "../components/alumno"
import Notificaciones_Orientadora from "../components/notificaciones-orientadora"
import Buscador from "../components/buscador"
import Boton_agregar from "../components/boton-agregar"
import Menu_agregarO from "../components/menu-agregarO"

import { useState } from "react"
import Alerta_Sin_Alumno from "../alerts/alertaSinAlumno"

const Orientadora = () => {
    const [menu, setMenu] = useState (true)
    const [alerta, setAlerta ] = useState (false)

    const [menuP, setMenuP] = useState(false)
    const [alumno, setAlumno] = useState([]);
    const [grupo, setGrupo] = useState ([]);
    const [semestre, setSemestre] = useState ([]);
    

    return(
        <>
            <CabeceraO />
            <Alumno/>
            <Buscador alumno={alumno} setAlumno={setAlumno} grupo={grupo} setGrupo={setGrupo} semestre={semestre} setSemestre={setSemestre} alerta={alerta} setAlerta={setAlerta} menu={menu} setMenu={setMenu} />
            <Notificaciones_Orientadora alumno={alumno} grupo={grupo} semestre={semestre} menu={menu} setMenu={setMenu}/>
            <Boton_agregar menuP={menuP} setMenuP={setMenuP} menu={menu} setMenu={setMenu}/>
            <Menu_agregarO menuP={menuP} setMenuP={setMenuP}/>
            <Alerta_Sin_Alumno alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default Orientadora