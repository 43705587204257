import './menu-agregar.css'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_citatorio from '../assets/icon_citatorio.png'
import Icon_falta from '../assets/icon_falta.png'
import Icon_retardo from '../assets/icon_retardo.png'
import Icon_justificacion from '../assets/icon_justificacion.png'
import Icon_tarea from '../assets/icon_tarea.png'

import { Link } from 'react-router-dom'


const Menu_agregar = ({menuP, setMenuP}) => {

    const mostrarMenuP = () => {
        setMenuP(!menuP)
    }

    return(
        <section className={`menu-agregar-contenedor  ${ menuP ? 'mostrar' : ''}`}>

            <div className='menu-agregar-contenedor-cabecera'>
                <h2>
                    AGREGAR AL ALUMNO
                </h2>

            </div>

            <div className='menu-agregar-contenedor-items'>

                <Link to='agregar-reporte' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_reporte}></img>            <h5>REPORTE</h5></Link>
                <Link to='agregar-citatorio' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_citatorio}></img>            <h5>CITATORIO</h5></Link>
                <Link to='agregar-falta' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_falta}></img>            <h5>FALTA</h5></Link>
                <Link to='agregar-retardo' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_retardo}></img>            <h5>RETARDO</h5></Link>
                <Link to='agregar-justificacion' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_justificacion}></img>            <h5>JUSTIFICACIÓN</h5></Link>
                <Link to='agregar-tarea-atrasada' className='menu-agregar-contenedor-items-botones'><img className='icon' src={Icon_tarea}></img>            <h5>TAREA</h5></Link>

            </div>

            <div className='menu-agregar-contenedor-cancelar'>
                <button onClick={mostrarMenuP}>
                    CANCELAR
                </button>

            </div>

        </section>

    )
}

export default Menu_agregar

