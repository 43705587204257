import Reporte from "../components/reporte"
import Cabecera from '../components/cabecera'
import Alerta_Eliminar from "../alerts/alertaEliminar"
import { useState } from "react"



const Reportes = () => {
    const [alerta, setAlerta ] = useState (false)
  

    return(
        <>
        <Cabecera/>
        <Reporte alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_Eliminar alerta={alerta} setAlerta={setAlerta}/>
        

        </>
    )
}

export default Reportes