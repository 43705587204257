import './formulario-alumno.css'
import { useForm } from 'react-hook-form' 
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Formulario_Alumno = ({tutor, menu, setMenu} ) => {
    const tutorId = (tutor.id)
    const navigate = useNavigate ()

    const {register, handleSubmit, setValue} = useForm();

    useEffect(() => {
        // Establecer el valor por defecto después de la renderización inicial
        setValue('Tutores_id', tutorId);
      }, [tutorId]);

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/alumnos/', data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            console.log('Solicitud POST exitosa', response);
            navigate('/administrador/orientadora/alumnos')
            console.log(data)
            console.log(tutorId)
             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud POST', error);
            });
    }

    

    return(
        
        <section className={`formulario-alumno-contenedor ${ menu ? 'estaActivo' : ''}`}>
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className='formulario-alumno-formulario'>

                <label>MATRÍCULA</label>
                <input type='text' {...register ('matricula', {
                    required: true
                })}></input>

                <label>NOMBRES</label>
                <input type='text' {...register ('nombre', {
                    required: true
                })}></input>

                <label>APELLIDO PATERNO</label>
                <input type='text' {...register ('apellidoP', {
                    required: true
                })}></input>

                <label>APELLIDO MATERNO</label>
                <input type='text' {...register ('apellidoM', {
                    required: true
                })}></input>

                {/* <label type='hidden'>TURNO</label>*/}
                <input type='hidden' defaultValue={'MATUTINO'} {...register('turno')}></input>

                <label>TELEFONO </label>
                <input type='text' {...register ('telefono', {
                    required: true
                })}></input>

                {/* <label type='hidden'>NO REPORTES</label>*/}
                <input type='hidden' defaultValue={'0'} {...register('numero_reportes')}></input>

                {/* <label type='hidden'>NO RETARDOS</label>*/}
                <input type='hidden' defaultValue={'0'} {...register('numero_retardos')}></input>

                {/* <label type='hidden'>TUTOR</label>*/}
                <input type='hidden' defaultValue={tutorId} {...register('Tutores_id')}></input>

                <label>GRADO Y GRUPO</label>
                <select className='select' {...register ('Grupo_Semestres_id', {
                    required: true
                })}>
                    <option value={1}>1A</option>
                    <option value={2}>1B</option>
                    <option value={3}>2A</option>
                    <option value={4}>2B</option>
                    <option value={5}>3A</option>
                    <option value={6}>3B</option>
                    <option value={7}>4A</option>
                    <option value={8}>4B</option>
                    <option value={9}>5A</option>
                    <option value={10}>5B</option>
                    <option value={11}>6A</option>
                    <option value={12}>6B</option>

                </select>

                <button>AGREGAR ALUMNO</button>
            </form>

        </section>
        

    )
}

export default Formulario_Alumno