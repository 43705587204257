import './retardo.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Retardo = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [retardos, setRetardos] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/retardos/")
        .then((res) => setRetardos(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )

    return(

        <>
        {retardos.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        retardos.map((retardo) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === retardo.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlRetardo', JSON.stringify(retardo.Alumnos_id))
          };

           //////
           const mandarRetardo = () => {
            localStorage.setItem('idRetardo', JSON.stringify(retardo.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarRetardo()
          }
            return(

        <section key={retardo.id} className='retardo'>
            <div className='retardo-contenedor'>
                <span>RETARDO {retardo.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='retardo-botones'>
                <Link onClick={mandarAlumno} className="link-retardo" to={`/administrador/prefecto/retardos/${retardo.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
         )
        })
        )}
      </>
    );
  };

export default Retardo