import CabeceraO from '../components/cabeceraO'
import Detalles_Tutor from '../components/detalles-tutor'


const Ver_Tutor = () => {

    return(
        <>
        <CabeceraO/> 
        <Detalles_Tutor/>
        </>
    )
}

export default Ver_Tutor