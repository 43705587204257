import '../alerts/alerta.css'
import Axios from "axios"
import { useNavigate } from 'react-router-dom'


const AlertaRET = ({alerta, setAlerta,}) => {
    const navigate = useNavigate ()

    const mostrarAlerta = () =>{
        setAlerta(!alerta)
    }

    
    const idRET = localStorage.getItem("idRET")

    const deleteRET = async (idRET) => {
        await Axios.delete(`https://plantel-candelaria.onrender.com/retardos/${idRET}`)
        

        window.location.reload();
    }

    const eliminarDatos = ( )=>{
        mostrarAlerta()
        deleteRET(idRET)
      }

    return(
        <section className={`alerta-seccion ${ alerta ? 'mostrar' : ''}`}>
            <div className='alerta-contenedor'>
                <div className='alerta-cabecera'>
                    <h3>ELIMINAR</h3>

                </div >
                <div className='alerta-cuerpo'>
                    <h4>DESEA ELIMINAR ESTE REGISTRO</h4>
                    <button onClick={eliminarDatos}>ACEPTAR</button>
                    <button onClick={mostrarAlerta}>NO</button>
                </div>
            </div>    
        </section>
    )
}

export default AlertaRET