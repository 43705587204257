import './fai.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"
import { useParams } from 'react-router-dom'

const Justificacion_Alumno = ({alerta, setAlerta, justificaciones, alumno}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

       //////////
      


    return(
        <>
        {justificaciones.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (


        justificaciones.map(justificacion => {

          const mandarAlumno = () => {
            localStorage.setItem('idAlJustificacion', JSON.stringify(justificacion.Alumnos_id))
          };

            //////
           const mandarJustificacion = () => {
            localStorage.setItem('idJUS', JSON.stringify(justificacion.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarJustificacion()
          }
            return (
        

        <section key={justificacion.id} className='fai'>
            <div className='fai-contenedor'>
                <span>JUSTIFICACION {justificacion.id} </span>

                

                <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                <div className='fai-botones'>
                    <Link onClick={mandarAlumno} to={`/administrador/prefecto/justificaciones/${justificacion.id}`}  className="link-fai">DETALLES</Link>
                    <button  onClick={mandarDatos} >ELIMINAR</button>
                </div>
            </div>
            
        </section>);
        })
      )}
    </>
  );
};

export default Justificacion_Alumno