import CabeceraT from '../components/cabeceraT'
import Reporte_AlumnoT from '../consultas/reporteT'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'



const Ver_ReporteTutor = () => {
    const { alumnoId } = useParams();
    const [alumno, setAlumno ] = useState ([]);
    const [reportes, setReportes ] = useState ([]);

    console.log(alumnoId)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarReporte", {Alumnos_id: alumnoId})
            .then(res => setReportes(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <CabeceraT/> 
        <h2>REPORTES</h2>
        <Reporte_AlumnoT alumno={alumno} reportes={reportes} />
        
        </>
    )
}

export default Ver_ReporteTutor