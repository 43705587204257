import './formulario-usuario.css'
import { useForm } from 'react-hook-form' 
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Formulario_Usuario = () => {
    const navigate = useNavigate ()


    const {register, handleSubmit} = useForm();

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/administradores/', data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            console.log('Solicitud POST exitosa', response);
            navigate('/administrador/directora/usuarios')
             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud POST', error);
            });
    }






    return(
        <section className='formulario-usuario-contenedor'>
            <form onSubmit={handleSubmit( onSubmit)}
            className='formulario-usuario-formulario'>

                <label>NOMBRE(S)</label>
                <input type='text' {...register('nombre', {
                    required: true
                })}></input>

                <label>APELLIDO PATERNO</label>
                <input type='text' {...register('apellidoP', {
                    required: true
                })}></input>

                <label>APELLIDO MATERNO</label>
                <input type='text' {...register('apellidoM', {
                    required: true
                })}></input>

                <label>TELÉFONO</label>
                <input type='text' {...register('telefono', {
                    required: true
                })}></input>

                <label>USUARIO</label>
                <input type='text' {...register('usuario', {
                    required: true
                })}></input>

                <label>CONTRASEÑA</label>
                <input type='text' {...register('contraseña', {
                    required: true
                })}></input>

                <label>CARGO</label>
                <select className='select' {...register ('Cargo', {
                    required: true
                })}>
                    <option value={1}>DIRECTOR(A)</option>
                    <option value={2}>ORIENTADOR(A)</option>
                    <option value={3}>PREFECTO</option>
                </select>

                <button>AGREGAR USUARIO</button>

            </form>

        </section>

    )
}

export default Formulario_Usuario