import CabeceraD from '../components/cabeceraD'
import Detalles_Usuario from '../components/detalles-usuario'




const Usuarios_Lista = () => {


    return(
        <>
        <CabeceraD/>
        <Detalles_Usuario/>
        
        </>
    )
}

export default Usuarios_Lista