import CabeceraT from "../components/cabeceraT"
import Notificaciones_tutor from "../components/notificaciones-tutor"

import  Axios  from 'axios'
import { useState, useEffect } from "react"

const Tutor = () => {
    const [alumno, setAlumno ] = useState ([]);
    const [tutor, setTutor ] = useState ([]);

    const idTutor = localStorage.getItem("tutor")
    console.log(idTutor)

    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarAlumnoTutor", {Tutores_id: idTutor})
            .then(res => setAlumno(res.data))
           
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/tutores/${idTutor}`)
            .then(res => setTutor(res.data))
    }, [] )
 console.log(alumno)
    return(
        <>


        <CabeceraT tutor={tutor} />
        <Notificaciones_tutor alumno={alumno} />
        </>
    )
}

export default Tutor