import Cabecera from '../components/cabecera'
import Alerta_CIT from "../alerts/alertaCIT"
import Citatorio_Alumno from '../consultas/citatorio'

import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'


const Citatorios_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [citatorios, setCitatorios ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarCitatorio", {Alumnos_id: alumnoId})
            .then(res => setCitatorios(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <Cabecera/>
        <Citatorio_Alumno alerta={alerta} setAlerta={setAlerta} citatorios={citatorios} alumno={alumno}/>
        <Alerta_CIT alerta={alerta} setAlerta={setAlerta} citatorios={citatorios}/>
        
        </>
    )
}

export default Citatorios_Alumno