import CabeceraO from '../components/cabeceraO'
import Detalles_Alumno from '../components/detalles-alumno'


const Ver_Alumno = () => {

    return(
        <>
        <CabeceraO/> 
        <Detalles_Alumno/>
        </>
    )
}

export default Ver_Alumno