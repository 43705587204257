import './detalles-tarea.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Alumno = () => {
    const { alumnoId } = useParams()
    
    const [alumno, setAlumno] = useState ([]);
    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }




    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
                .then(res => setAlumno(res.data));
                
                            
    }, [alumnoId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        switch (cargoNumero) {
            case 1:
                setGrupo('1');
                setSemestre('A')
                break;
            case 2:
                setGrupo('1');
                setSemestre('B')
                break;
            case 3:
                setGrupo('2');
                setSemestre('A')
                break;
            // Agrega más casos según sea necesario
            case 4:
                setGrupo('2');
                setSemestre('B')
                break;
            case 5:
                setGrupo('3');
                setSemestre('A')
            break;
            case 6:
                setGrupo('3');
                setSemestre('B')
                break;
            case 7:
                setGrupo('4');
                setSemestre('A')
                break;
            case 8:
                setGrupo('4');
                setSemestre('B')
                break;
                // Agrega más casos según sea necesario
            case 9:
                setGrupo('5');
                setSemestre('A')
                break;
            case 10:
                setGrupo('5');
                setSemestre('B')
                break;
            case 11:
                setGrupo('6');
                setSemestre('A')
                break;
            case 12:
                setGrupo('6');
                setSemestre('B')
                break;
            // ... y así sucesivamente hasta 12 casos
            default:
                
                break;
        }
    }, [alumno.Grupo_Semestres_id]);

    return(
        
        <>
        
        {alumno &&( 
            
            <>

        <section className='detalles-tarea'>
            <div className='detalles-tarea-contenedor'>
                <div className='detalles-tarea-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    {/*<h4>{tarea.fecha}</h4>*/}
                    <h4>ALUMNO {alumno.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-tarea-datos-alumno'>
                           
                        

                             
                    <span>MATRÍCULA: {alumno.matricula}</span>
                    <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>
                    <div>
                        <span>GRADO: {grupo} </span>
                        <span>GRUPO: {semestre}</span>
                    </div> 
                    <span>TELÉFONO: {alumno.telefono}</span>
                        
                       
                
                </div>

                {/*<hr></hr>
                <div className='detalles-tarea-datos-tarea'>
                    <div>
                        <p><span>USUARIO: {alumno.usuario} </span></p>  
                    </div>

                    <div>
                        <p><span>CONTRASEÑA: {alumno.contraseña}</span></p>
                    </div>
 
                    
    </div>*/}

                <button onClick={atras} className='detalles-tarea-eliminar'>ACEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Alumno