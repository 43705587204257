import './menu-horizontal.css'
import Icon_tutores from '../assets/icon_tutores.png'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_citatorio from '../assets/icon_citatorio.png'
import Icon_falta from '../assets/icon_falta.png'
import Icon_retardo from '../assets/icon_retardo.png'
import Icon_justificacion from '../assets/icon_justificacion.png'
import Icon_tarea from '../assets/icon_tarea.png'
import Icon_cerrar from '../assets/icon_cerrar.png'
import Icon_salir from '../assets/icon_salir.png'

import { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom'

const Menu_horizontalT = ({ menu, setMenu, tutor}) =>{
           
            {/*const [menu, setMenu ] = useState (false)*/}


            const mostrarMenu = () => {
                setMenu( !menu)
            }

            const navigate = useNavigate()

            //para eliminar el localstorage
            const logOut = () => {
                localStorage.removeItem("auth")
                localStorage.removeItem("cargo")
                navigate('/')
            }

           
    return(
        <>
      {tutor && (
        <section key={tutor.id} className={`menu-horizontal-contenedor ${ menu ? 'estaActivo' : ''}`}>

           

            <div className='menu-horizontal-contenedor-administrador'>
                <img src={Icon_tutores}></img>

                

                <h4>TUTOR</h4>
                <h4>{tutor.nombre} </h4>
                <h4>{tutor.apellidoP} {tutor.apellidoM} </h4>
                


                
            </div>

            <hr className='menu-horizontal-separador'></hr>

            <div className='menu-horizontal-cerrar'> 
                <h3>MENÚ</h3>
                <img src={Icon_cerrar}
                    onClick={mostrarMenu}></img>
            </div>

            <nav className='menu-horizontal-contenedor-nav'>
                <ul className='menu-horizontal-contenedor-lista'>

                   <h4 onClick={logOut} className='menu-horizontal-lista1'>
                        <img src={Icon_salir}></img>
                        <span>CERRAR SESIÓN</span>
                    </h4> 

                    
                    
                </ul>
            </nav>

        </section>
         
         )}        
         </>
        
    )
}

export default Menu_horizontalT