import './fai.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"
import { useParams } from 'react-router-dom'

const Fvd_Alumno = ({alerta, setAlerta, fvds, alumno}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

       //////////
      


    return(
        <>
        {fvds.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        fvds.map(fvd => {

          const mandarAlumno = () => {
            localStorage.setItem('idAlFvd', JSON.stringify(fvd.Alumnos_id))
          };

            //////
           const mandarFvd = () => {
            localStorage.setItem('idFVD', JSON.stringify(fvd.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarFvd()
          }
            return (
        

        <section key={fvd.id} className='fai'>
            <div className='fai-contenedor'>
                <span>VISITA DOMICILIARIA {fvd.id} </span>

                

                <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                <div className='fai-botones'>
                    <Link onClick={mandarAlumno} to={`/administrador/orientadora/fvds/${fvd.id}`}  className="link-fai">DETALLES</Link>
                    <button  onClick={mandarDatos} >ELIMINAR</button>
                </div>
            </div>
            
        </section>) })
      )}
    </>
  );
};

export default Fvd_Alumno