import './link-usuario.css'
import {Link} from 'react-router-dom'

const Link_Alumno = () => {

    
    return(
        <section className='link-usuario-contenedor'>
            <Link to='/administrador/orientadora/alumnos/nuevo'
                className='link-usuario-boton'>
                + NUEVO ALUMNO
            </Link>

        </section>
    )
}

export default Link_Alumno