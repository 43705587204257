import './formulario-fai.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'

const Formulario_Fai = () => {
    const {register, handleSubmit} = useForm()
    const navigate = useNavigate ()

    const id = localStorage.getItem('id');

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/fais/', data)
            .then((response) => {
            // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje
            console.log('Solicitud POST exitosa', response);
            navigate('/administrador/orientadora/')
            console.log(data)
             })
            .catch((error) => {
            // Manejar errores en la solicitud, por ejemplo, mostrar un mensaje de error
            console.error('Error en la solicitud POST', error);
            });
        }

    return(
        <section className='formulario-fai-contenedor'>
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className='formulario-fai-formulario'>
                <label>EDAD</label>
                <input type='text' {...register ('edad_alumno', {
                    required: true
                })}></input>

                <label>MOTIVO</label>
                <input type='text' {...register ('motivo', {
                    required: true
                })}></input>

                <label>ESTRATEGIA</label>
                <input type='text' {...register ('estrategia', {
                    required: true
                })}></input>

                <label>RESULTADOS</label>
                <input type='text' {...register ('resultados', {
                    required: true
                })}></input>

                {/*<label>RESULTADOS</label>*/}
                <input type='hidden' defaultValue={id} {...register ('Alumnos_id', {
                    required: true
                })}></input>

                <button>AGREGAR ATENCIÓN INDIVIDUAL</button>
            </form>

        </section>

    )
}

export default Formulario_Fai