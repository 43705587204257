import Alerta_EliminarFvd from '../alerts/alertaEliminarFvd'
import CabeceraO from '../components/cabeceraO'
import Fvd from '../components/fvd'
import { useState } from "react"


const Fvds = () => {
    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <CabeceraO/>
        <Fvd alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarFvd alerta={alerta} setAlerta={setAlerta}/>
        
        </>
    )
}

export default Fvds