import './fvd.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Fvd = ({alerta, setAlerta}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [fvds, setFvds] = useState ([]);
    const [alumnos, setAlumnos] = useState([])

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/fvds/")
        .then((res) => setFvds(res.data.sort((a, b) => b.id - a.id)));


        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
            .then(res => setAlumnos(res.data))
    }, [] )


    return(
        <>
        {fvds.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        fvds.map((fvd) => {
        // Encuentra el alumno correspondiente al informe actual
        const alumnoAsociado = alumnos.find((alumno) => alumno.id === fvd.Alumnos_id );

        const mandarAlumno = () => {
            localStorage.setItem('idAlFvd', JSON.stringify(fvd.Alumnos_id))
          };
          //////
          const mandarFvd = () => {
            localStorage.setItem('idFvd', JSON.stringify(fvd.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarFvd()
          }
            return(
        <section key={fvd.id} className='fvd'>
            <div className='fvd-contenedor'>
                <span>VISITA DOMICILIARIA {fvd.id}</span>

                <span>{alumnoAsociado ? `${alumnoAsociado.nombre} ${alumnoAsociado.apellidoP} ${alumnoAsociado.apellidoM}` : "No disponible"} </span>

                <div className='fvd-botones'>
                <Link onClick={mandarAlumno} className="link-fvd" to={`/administrador/orientadora/fvds/${fvd.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
        )
    })
    )}
  </>
);
};

export default Fvd