import CabeceraO from "../components/cabeceraO"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Fvd from "../components/formulario-fvd"


const Agregar_FVD = () => {
    return(
        <>
            <CabeceraO/>
            <Datos_Alumno/>
            <Formulario_Fvd/>
        </>
    )
}

export default Agregar_FVD