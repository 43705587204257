import Notificaciones_prefecto from "../components/notificaciones-prefecto"
import Cabecera from "../components/cabecera"
import Alumno from "../components/alumno"
import Buscador from "../components/buscador"
import Menu_agregar from "../components/menu-agregar"
import Boton_agregar from "../components/boton-agregar"

import { useState } from "react"
import Alerta_Sin_Alumno from "../alerts/alertaSinAlumno"



const Prefecto = () => {
    const [menu, setMenu] = useState (true)

    const [alerta, setAlerta ] = useState (false)

    const [menuP, setMenuP] = useState(false);
    const [alumno, setAlumno] = useState([]);
    const [grupo, setGrupo] = useState ([]);
    const [semestre, setSemestre] = useState ([]);


    return(
        <>
            <Cabecera/>
            <Alumno/>
            <Buscador alumno={alumno} setAlumno={setAlumno} grupo={grupo} setGrupo={setGrupo} semestre={semestre} setSemestre={setSemestre} alerta={alerta} setAlerta={setAlerta} menu={menu} setMenu={setMenu}/>
            <Notificaciones_prefecto alumno={alumno} grupo={grupo} semestre={semestre} menu={menu} setMenu={setMenu} />
            <Boton_agregar menuP={menuP} setMenuP={setMenuP} menu={menu} setMenu={setMenu}/>
            <Menu_agregar menuP={menuP} setMenuP={setMenuP}/>
            <Alerta_Sin_Alumno alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default Prefecto