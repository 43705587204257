import './detalles-citatorio.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Citatorio = () => {

    const { citatorioId } = useParams()
    
    const [citatorio, setCitatorio] = useState ([]);
    const [alumno, setAlumno] = useState([]);
    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    const idAlCitatorio = localStorage.getItem("idAlCitatorio")

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }

    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/citatorios/${citatorioId}`)
                .then(res => setCitatorio(res.data)); 

            
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${idAlCitatorio}`)
                .then(res => { setAlumno(res.data)
                    
                }); 
                
    }, [citatorioId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        switch (cargoNumero) {
            case 1:
                setGrupo('1');
                setSemestre('A')
                break;
            case 2:
                setGrupo('1');
                setSemestre('B')
                break;
            case 3:
                setGrupo('2');
                setSemestre('A')
                break;
            // Agrega más casos según sea necesario
            case 4:
                setGrupo('2');
                setSemestre('B')
                break;
            case 5:
                setGrupo('3');
                setSemestre('A')
            break;
            case 6:
                setGrupo('3');
                setSemestre('B')
                break;
            case 7:
                setGrupo('4');
                setSemestre('A')
                break;
            case 8:
                setGrupo('4');
                setSemestre('B')
                break;
                // Agrega más casos según sea necesario
            case 9:
                setGrupo('5');
                setSemestre('A')
                break;
            case 10:
                setGrupo('5');
                setSemestre('B')
                break;
            case 11:
                setGrupo('6');
                setSemestre('A')
                break;
            case 12:
                setGrupo('6');
                setSemestre('B')
                break;
            // ... y así sucesivamente hasta 12 casos
            default:
                
                break;
        }
    }, [alumno.Grupo_Semestres_id]);

    return(

        <>
        {citatorio && alumno &&( 
            <>

        <section className='detalles-citatorio'>
            <div className='detalles-citatorio-contenedor'>
                <div className='detalles-citatorio-cabecera'>

                    <h4>PLANTEL CANDELARIA</h4>
                    <h4>{citatorio.fecha}</h4>
                    <h4>CITATORIO {citatorio.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-citatorio-datos-alumno'>
                           
                        <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                        <div>
                            <span>GRADO: {semestre} </span>
                            <span>GRUPO: {grupo} </span>
                        </div>

                        <span>TURNO: MATUTINO</span>
                
                </div>

                <hr></hr>
                <div className='detalles-citatorio-datos-citatorio'>
                    <div>
                        <p><span>ASUNTO: </span> {citatorio.asunto}</p>  
                    </div>

                    <div>
                        <p><span>FECHA CITATORIO: </span> {citatorio.fecha_cita}</p>
                    </div>

                    <div>
                        <p><span>LUGAR: </span> {citatorio.lugar}</p>
                    </div>

                    <div>
                        <p><span>HORA: </span>{citatorio.hora}</p>
                    </div>
                    
                </div>

                <button onClick={atras} className='detalles-citatorio-eliminar'>ACEPTAR</button>

            </div>
        </section>
    </>
    )}
    </>
    )
}

export default Detalles_Citatorio