import CabeceraT from '../components/cabeceraT.js'
import Detalles_Retardo from '../components/detalles-retardo.js'


const Ver_RetardoT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Retardo/>
        </>
    )
}

export default Ver_RetardoT