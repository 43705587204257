import './detalles-fvd.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Usuario = () => {

    const { usuarioId } = useParams()
    
    const [usuario, setUsuario] = useState ([]);

    const [puesto, setPuesto] = useState ()


    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }

                

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/administradores/${usuarioId}`)
                .then(res => setUsuario(res.data)); 
               
    }, [usuarioId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(usuario.cargo);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        if (cargoNumero === 1) {
            setPuesto('DIRECTORA');
        } else if (cargoNumero === 2) {
            setPuesto('ORIENTADORA');
        } else if (cargoNumero === 3) {
            setPuesto('PREFECTO');
        } else {
            // Puedes manejar otros casos si es necesario
            setPuesto('Desconocido');
        }
    }, [usuario.cargo]);


    
 
    return(
        <>
        {usuario &&( 

   

                   


    // Resto del código
    

            
            <>
            <section className='detalles-fvd'>

             
            <div className='detalles-fvd-contenedor'>
                <div className='detalles-fvd-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    {/*<h4>14 de Octubre de 2023</h4>*/}
                    <h4>USUARIO {usuario.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-fvd-datos-alumno'>
                           
                        <span>{usuario.nombre} {usuario.apellidoP} {usuario.apellidoM} </span>

                        <span>CARGO: {puesto} </span>

                        <span>TELEFONO: {usuario.telefono}</span>
                
                </div>

                <hr></hr>
                <div className='detalles-fvd-datos-fvd'>
                    <div>
                        <p><span>USUARIO: </span>{usuario.usuario}</p>  
                    </div>

                    <div>
                        <p><span>CONTRASEÑA: </span>{usuario.contraseña}</p>
                    </div>

                </div>

                <button onClick={atras} className='detalles-fvd-eliminar'>ACEPTAR</button>

                </div>
            </section> </>
        
        )
  
        
        }</>   
       
    )
}

export default Detalles_Usuario