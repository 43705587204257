import './alumno.css';
import Icon_alumno from '../assets/icon_alumno.png'


const Alumno = () => {
    return(
        <section className='alumno-contenedor'>
            <img src={Icon_alumno}>
            </img>

        </section>

    )
}

export default Alumno;