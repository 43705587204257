const Cerrar_Sesion = () => {
    return(
        <>
        <h1>CERRAR SESIÓN</h1>

        <div className="contenedor">
            <input type="date"></input>

            <select>
                <option>Dato 1</option>
                <option>Dato 2</option>
                <option>Dato 3</option>
            </select>

            <input type="time"></input>
        </div>    
        </>
    )
}

export default Cerrar_Sesion