import './login-administrador.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'

const Login_Administrador = ({alerta, setAlerta}) =>{
    const verAlerta = () =>{
        setAlerta(!alerta)
       }
       
    const {register, handleSubmit} = useForm()
        
    const navigate = useNavigate();

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/login', data)
        .then(({data}) =>{           
            localStorage.setItem('cargo', JSON.stringify(data.cargo))
            localStorage.setItem('auth', 'true')
            console.log(data.cargo)

            if (data.cargo === '3') {
                navigate('prefecto')
            } else {
                if (data.cargo === '2') {
                    navigate('orientadora')
                } else {
                    if (data.cargo === '1') {
                        navigate('directora')
                    } 
                }
            }                      
        })
        .catch(({response}) =>{
            verAlerta()
            console.log(response)
        })       
    }

    return(
        <section className="login-administrador-contenedor">
            <form onSubmit={handleSubmit(onSubmit)} 
                className="login-administrador-formulario">

                <label>USUARIO</label>
                <input type='text' {...register ('usuario', {
                    required: true
                })}></input>

                <label>CONTRASEÑA</label>
                <input type='password' {...register ('contraseña', {
                    required: true
                })}></input>

                <button className='login-administrador-boton-separador' type='submit'>INICIAR SESIÓN</button>
                


            </form>
            <button>RECUPERAR ACCESO</button>
        </section>

    )
}

export default Login_Administrador