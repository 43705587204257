import Cabecera from "../components/cabecera"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Reporte from "../components/formulario-reporte"

const Agregar_Reporte = () => {
    return(
        <>
            <Cabecera/>
            <Datos_Alumno/>
            <Formulario_Reporte/>
        </>    
    )
}
export default Agregar_Reporte