import './detalles-falta.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Falta = () => {

    const { faltaId } = useParams()
    
    const [falta, setFalta] = useState ([]);
    const [alumno, setAlumno] = useState([]);
    const [grupo, setGrupo] = useState ()
    const [semestre, setSemestre] = useState ()

    const idAlReporte = localStorage.getItem("idAlFalta")

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }

    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/faltas/${faltaId}`)
                .then(res => setFalta(res.data)); 

            
            Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${idAlReporte}`)
                .then(res => { setAlumno(res.data)
                    
                }); 
                
    }, [faltaId]);

    useEffect(() => {
        // Asegurémonos de que usuario.cargo es un número antes de comparar
        const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

        // Verificar el valor de cargoNumero y asignar el puesto correspondiente
        switch (cargoNumero) {
            case 1:
                setGrupo('1');
                setSemestre('A')
                break;
            case 2:
                setGrupo('1');
                setSemestre('B')
                break;
            case 3:
                setGrupo('2');
                setSemestre('A')
                break;
            // Agrega más casos según sea necesario
            case 4:
                setGrupo('2');
                setSemestre('B')
                break;
            case 5:
                setGrupo('3');
                setSemestre('A')
            break;
            case 6:
                setGrupo('3');
                setSemestre('B')
                break;
            case 7:
                setGrupo('4');
                setSemestre('A')
                break;
            case 8:
                setGrupo('4');
                setSemestre('B')
                break;
                // Agrega más casos según sea necesario
            case 9:
                setGrupo('5');
                setSemestre('A')
                break;
            case 10:
                setGrupo('5');
                setSemestre('B')
                break;
            case 11:
                setGrupo('6');
                setSemestre('A')
                break;
            case 12:
                setGrupo('6');
                setSemestre('B')
                break;
            // ... y así sucesivamente hasta 12 casos
            default:
                
                break;
        }
    }, [alumno.Grupo_Semestres_id]);

    return(

        <>
        {falta && alumno &&( 
            <>

        <section className='detalles-falta'>
            <div className='detalles-falta-contenedor'>
                <div className='detalles-falta-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    <h4>{falta.fecha}</h4>
                    <h4>FALTA {falta.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-falta-datos-alumno'>
                           
                        <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM} </span>

                        <div>
                            <span>GRADO: {grupo} </span>
                            <span>GRUPO: {semestre}</span>
                        </div>

                        <span>TURNO: MATUTINO</span>
                
                </div>

                <hr></hr>
                <div className='detalles-falta-datos-falta'>
                    <div>
                        <p><span>FECHA LÍMITE PARA JUSTIFICAR: </span>{falta.fecha}</p>  
                    </div>

                
                    
                </div>

                <button onClick={atras} className='detalles-falta-eliminar'>CEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Falta