import CabeceraT from '../components/cabeceraT'
import Detalles_Justificacion from '../components/detalles-justificacion'


const Ver_JustificacionT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Justificacion/>
        </>
    )
}

export default Ver_JustificacionT