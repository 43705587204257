import CabeceraO from '../components/cabeceraO'
import Fai from '../components/fai'
import Alerta_EliminarFai from "../alerts/alertaEliminarFai"
import { useState } from "react"


const Fais = () => {

    const [alerta, setAlerta ] = useState (false)

    return(
        <>
        <CabeceraO/>
        <Fai alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarFai alerta={alerta} setAlerta={setAlerta}/>
        
        </>
    )
}

export default Fais