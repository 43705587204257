import './formulario-retardo.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'

const Buscador_Tutor= ({tutor, setTutor, menu, setMenu, alerta, setAlerta} ) => {
    const {register, handleSubmit} = useForm()

    

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/buscarTutor', data)
        .then(({data}) =>{           

            setTutor(data)
            setMenu(!menu)
                     
        })
        .catch(({response}) =>{
            console.log(response)
            setAlerta(!alerta)
        })       
    }

    return(
        <section className='formulario-retardo-contenedor'>
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className='formulario-retardo-formulario'>

                <label>NOMBRE</label>
                <input type='text' {...register ('nombre', {
                    required: true
                })}></input>

                <label>APELLIDO PATERNO</label>
                <input type='text' {...register ('apellidoP', {
                    required: true
                })}></input>


                <button>BUSCAR TUTOR</button>
            </form>

        </section>

    )
}

export default Buscador_Tutor