import Cabecera from '../components/cabecera'
import Detalles_Tarea from '../components/detalles-tarea'


const Ver_Tarea_Atrasada = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Tarea/>
        </>
    )
}

export default Ver_Tarea_Atrasada