import './login-tutor.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'

const Login_Tutor = () =>{

    const {register, handleSubmit} = useForm()
        
    const navigate = useNavigate();

    const onSubmit = (data) => {
        Axios.post('https://plantel-candelaria.onrender.com/loginTutor', data)
        .then(({data}) =>{    
            localStorage.setItem('tutor', JSON.stringify(data.id))       
            localStorage.setItem('auth', 'true')

                console.log(data)
                navigate('inicio')
                                
        })
        .catch(({response}) =>{
            //verAlerta()
            console.log(response)
        })       
    }

    return(
        <section className="login-tutor-contenedor">
            <form onSubmit={handleSubmit(onSubmit)}
                className="login-tutor-formulario">

                <label>USUARIO</label>
                <input type='text'{...register ('usuario', {
                    required: true
                })}></input>

                <label>CONTRASEÑA</label>
                <input type='password' {...register ('contraseña', {
                    required: true
                })}></input>

                <button className='login-tutor-boton-separador' type='submit'>INICIAR SESIÓN</button>
                
            </form>
            <button>RECUPERAR ACCESO</button>

        </section>

    )
}

export default Login_Tutor