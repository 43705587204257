import './usuarios.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Usuarios = ({alerta, setAlerta} ) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

    const [usuarios, setUsuarios] = useState ([]);

    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/administradores/")
            .then(res => setUsuarios(res.data))

    }, [] )

    return(
        <>
        {usuarios.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        usuarios.map((usuario) => {

        const mandarUsuario = () => {
            localStorage.setItem('idUsu', JSON.stringify(usuario.id))
        };

        ///////
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarUsuario()
          }

            return(
        <section key={usuario.id} className='usuarios'>
            <div className='usuarios-contenedor'>
                <span>USUARIO {usuario.id} </span>

                <span>{usuario.nombre} {usuario.apellidoP} {usuario.apellidoM}</span>

                <div className='usuarios-botones'>
                <Link onClick={mandarUsuario} className="link-usuario" to={`/administrador/directora/usuarios/${usuario.id}`}>DETALLES</Link>
                    <button onClick={mandarDatos}>ELIMINAR</button>
                </div>
            </div>
        </section>
          )
        })
        )}
      </>
    );
  };

export default Usuarios