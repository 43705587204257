import CabeceraT from '../components/cabeceraT.js'
import Detalles_Reporte from '../components/detalles-reporte'


const Ver_ReporteT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Reporte/>
        </>
    )
}

export default Ver_ReporteT