import Cabecera from "../components/cabecera"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Citatorio from "../components/formulario-citatorio"

const Agregar_Citatorio = () => {
    return(
        <>
            <Cabecera/>
            <Datos_Alumno/>
            <Formulario_Citatorio/>
        </>    
    )
}
export default Agregar_Citatorio