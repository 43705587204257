import CabeceraT from '../components/cabeceraT.js'
import Detalles_Falta from '../components/detalles-falta.js'


const Ver_ReporteT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Falta/>
        </>
    )
}

export default Ver_ReporteT