import { Navigate, Outlet } from "react-router-dom"

const Rutas_Protegidas = ({
    redirectPath = '/'
}) => {
    const canActivate = localStorage.getItem("auth")
    const cargo = localStorage.getItem("cargo")

    if(canActivate && cargo === '"3"'){
       return <Outlet/>     
    } 

    return <Navigate to={redirectPath}/>
    
}

export default Rutas_Protegidas