import CabeceraO from '../components/cabeceraO'
import Fai from '../components/fai'
import Alerta_Fai from "../alerts/alertaFAI"
import { useState, useEffect } from "react"
import Fai_Alumno from '../consultas/fai'
import { useParams } from 'react-router-dom'
import  Axios  from 'axios'

const Fais_Alumno = () => {
    const { alumnoId } = useParams();
    const [alerta, setAlerta ] = useState (false)
    const [fais, setFais ] = useState ([]);
    const [alumno, setAlumno ] = useState ([]);


    useEffect(() =>{
        Axios.post("https://plantel-candelaria.onrender.com/buscarFai", {Alumnos_id: alumnoId})
            .then(res => setFais(res.data))
    }, [] )

    useEffect(() =>{
        Axios.get(`https://plantel-candelaria.onrender.com/alumnos/${alumnoId}`)
            .then(res => setAlumno(res.data))
    }, [] )

    return(
        <>
        <CabeceraO/>
        <Fai_Alumno alerta={alerta} setAlerta={setAlerta} fais={fais} alumno={alumno}/>
        <Alerta_Fai alerta={alerta} setAlerta={setAlerta} fais={fais}/>
        
        </>
    )
}

export default Fais_Alumno