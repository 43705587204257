import CabeceraO from "../components/cabeceraO"
import Datos_Alumno from "../components/datos-alumno"
import Formulario_Fai from "../components/formulario-fai"


const Agregar_FAI = ( ) => {
    return(
        <>
            <CabeceraO/>
            <Datos_Alumno/>
            <Formulario_Fai/>
        </>
    )
}

export default Agregar_FAI