import './detalles-tarea.css'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const Detalles_Tutor = () => {
    const { tutorId } = useParams()
    
    const [tutor, setTutor] = useState ([]);

    const navigate = useNavigate()
    const atras = () => {
        navigate(-1)
    }



    

    useEffect(() => {
        
            Axios.get(`https://plantel-candelaria.onrender.com/tutores/${tutorId}`)
                .then(res => setTutor(res.data)); 

                
    }, [tutorId]);

    return(
        <>
        {tutor &&( 
            <>

        <section className='detalles-tarea'>
            <div className='detalles-tarea-contenedor'>
                <div className='detalles-tarea-cabecera'>
                    <h4>PLANTEL CANDELARIA</h4>
                    {/*<h4>{tarea.fecha}</h4>*/}
                    <h4>TUTOR {tutor.id}</h4>
                    

                </div>

                <hr></hr>
                <div className='detalles-tarea-datos-alumno'>
                           
                        <span>{tutor.nombre} {tutor.apellidoP} {tutor.apellidoM}</span>

                        {/*<div>
                            <span>GRADO: 1</span>
                            <span>GRUPO: B</span>
        </div>*/}

                        <span>COMUNIDAD: {tutor.comunidad}</span>
                        <span>TELÉFONO: {tutor.telefono}</span>
                
                </div>

                <hr></hr>
                <div className='detalles-tarea-datos-tarea'>
                    <div>
                        <p><span>USUARIO: {tutor.usuario} </span></p>  
                    </div>

                    <div>
                        <p><span>CONTRASEÑA: {tutor.contraseña}</span></p>
                    </div>
 
                    
                </div>

                <button onClick={atras} className='detalles-tarea-eliminar'>ACEPTAR</button>

            </div>
        </section>
        </>
        )}
        </>
    )
}

export default Detalles_Tutor