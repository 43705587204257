import './link-usuario.css'
import {Link} from 'react-router-dom'

const Link_Usuario = () => {

    
    return(
        <section className='link-usuario-contenedor'>
            <Link to='/administrador/directora/usuarios/nuevo'
                className='link-usuario-boton'>
                + NUEVO USUARIO
            </Link>

        </section>
    )
}

export default Link_Usuario