import './buscador.css';
import Icon_lupa from '../assets/icon_lupa.png'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useNavigate, Link, redirect } from 'react-router-dom'
import  Axios  from 'axios'


const Buscador = ({alumno, setAlumno, grupo, setGrupo, semestre, setSemestre, alerta, setAlerta, menu, setMenu} ) =>{
    const verAlerta = () =>{
        setAlerta(!alerta)
    }
    
    const {register, handleSubmit} = useForm()
   // const [gs, setGS] = useState ([]);


   const onSubmit = async (data) => {
    try {
      const response = await Axios.post('https://plantel-candelaria.onrender.com/buscarAlumno', data);
      const alumnoData = response.data;
  
      // Actualizar el estado utilizando la función de retorno
      setAlumno((prevAlumno) => {
        localStorage.setItem('id', JSON.stringify(alumnoData.id));
        localStorage.setItem('matricula', JSON.stringify(alumnoData.matricula));
        localStorage.setItem('nombre', JSON.stringify(alumnoData.nombre));
        localStorage.setItem('apellidoP', JSON.stringify(alumnoData.apellidoP));
        localStorage.setItem('apellidoM', JSON.stringify(alumnoData.apellidoM));
        localStorage.setItem('grupoSemestre', JSON.stringify(alumnoData.Grupo_Semestres_id));
  
        // Retornar el nuevo valor del estado
        return alumnoData;
      });
  
      setMenu(!menu);
    } catch (error) {
      console.error('Error al buscar alumno:', error);
      setAlerta(!alerta);
    }
  };


  {useEffect(() => {
    // Asegurémonos de que usuario.cargo es un número antes de comparar
    const cargoNumero = parseInt(alumno.Grupo_Semestres_id);

    // Verificar el valor de cargoNumero y asignar el puesto correspondiente
    switch (cargoNumero) {
        case 1:
            setGrupo('1');
            setSemestre('A')
            break;
        case 2:
            setGrupo('1');
            setSemestre('B')
            break;
        case 3:
            setGrupo('2');
            setSemestre('A')
            break;
        // Agrega más casos según sea necesario
        case 4:
            setGrupo('2');
            setSemestre('B')
            break;
        case 5:
            setGrupo('3');
            setSemestre('A')
        break;
        case 6:
            setGrupo('3');
            setSemestre('B')
            break;
        case 7:
            setGrupo('4');
            setSemestre('A')
            break;
        case 8:
            setGrupo('4');
            setSemestre('B')
            break;
            // Agrega más casos según sea necesario
        case 9:
            setGrupo('5');
            setSemestre('A')
            break;
        case 10:
            setGrupo('5');
            setSemestre('B')
            break;
        case 11:
            setGrupo('6');
            setSemestre('A')
            break;
        case 12:
            setGrupo('6');
            setSemestre('B')
            break;
        // ... y así sucesivamente hasta 12 casos
        default:
            
            break;
    }
}, [alumno.Grupo_Semestres_id]);}



    return(
      
        <section className='buscador-contenedor'>

            
            <form
                onSubmit={handleSubmit(onSubmit)}
                className='buscador-contenedor-formulario'>
                <input type='text' {...register ('matricula', {
                    required: true
                })} placeholder='MATRÍCULA'>
                </input>
                <button><img src={Icon_lupa}></img></button>
            </form>
            


        </section>
            
    )
}

export default Buscador;