import Cabecera from '../components/cabecera'
import Detalles_Reporte from '../components/detalles-reporte'


const Ver_Reporte = () => {

    return(
        <>
        <Cabecera/> 
        <Detalles_Reporte/>
        </>
    )
}

export default Ver_Reporte