import './alumnos.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"

const Alumnos = () => {

    const [alumnos, setAlumnos] = useState ([]);



    useEffect(() => {
        Axios.get("https://plantel-candelaria.onrender.com/alumnos/")
        .then((res) => setAlumnos(res.data.sort((a, b) => b.id - a.id)));

    }, [] )

    


    return(
        <>
        {alumnos.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        alumnos.map((alumno) => {

    return(
        <section key={alumno.id} className='alumnos'>
            <div className='alumnos-contenedor'>
                <span> MATRÍCULA: {alumno.matricula}</span>

                <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                <div className='alumnos-botones'>
                <Link className="link-alumno" to={`/administrador/orientadora/alumnos/${alumno.id}`}>DETALLES</Link>
                    {/*<button>ELIMINAR</button>*/}
                </div>
            </div>
        </section>
         )
        })
        )}
      </>
    );
  };

export default Alumnos