import './fai.css'

import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import Axios from "axios"
import { useParams } from 'react-router-dom'

const Tarea_Alumno = ({alerta, setAlerta, tareas, alumno}) => {
    const verAlerta = () =>{
        setAlerta(!alerta)
       }

       //////////
      


    return(
        <>
        {tareas.length === 0 ? ( // Verifica si el array de justificaciones está vacío
      <div className='no-registros'>
        <p>NO SE ENCONTRARON REGISTROS</p>
      </div>
    ) : (
        
        tareas.map(tarea => {

          const mandarAlumno = () => {
            localStorage.setItem('idAlTarea', JSON.stringify(tarea.Alumnos_id))
          };

            //////
           const mandarTarea = () => {
            localStorage.setItem('idTAR', JSON.stringify(tarea.id))
          };
//
          const mandarDatos = ( )=>{
            verAlerta()
            mandarTarea()
          }
            return (
        

        <section key={tarea.id} className='fai'>
            <div className='fai-contenedor'>
                <span>TAREA {tarea.id} </span>

                

                <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                <div className='fai-botones'>
                    <Link onClick={mandarAlumno} to={`/administrador/prefecto/tareas-atrasadas/${tarea.id}`}  className="link-fai">DETALLES</Link>
                    <button  onClick={mandarDatos} >ELIMINAR</button>
                </div>
            </div>
            
        </section>) })
      )}
    </>
  );
};

export default Tarea_Alumno