import './notificaciones-prefecto.css'
import Icon_reporte from '../assets/icon_reporte.png'
import Icon_citatorio from '../assets/icon_citatorio.png'
import Icon_falta from '../assets/icon_falta.png'
import Icon_retardo from '../assets/icon_retardo.png'
import Icon_justificacion from '../assets/icon_justificacion.png'
import Icon_tarea from '../assets/icon_tarea.png'
import Icon_reporteN from '../assets/icon_reporteN.png'
import Icon_citatorioN from '../assets/icon_citatorioN.png'
import Icon_faltaN from '../assets/icon_faltaN.png'
import Icon_retardoN from '../assets/icon_retardoN.png'
import Icon_justificacionN from '../assets/icon_justificacionN.png'
import Icon_tareaN from '../assets/icon_tareaN.png'
import { Link } from 'react-router-dom'

const Notificaciones_prefecto = ({alumno, grupo, semestre, menu, setMenu}) => {
    return(
        <>
        {alumno && (
            <>
            {grupo && (
                <>
                {semestre && (
        <section className={`notificaciones-prefecto ${ menu ? 'estaActivo' : ''}`}>

            <div className='notificaciones-prefecto-contenedor'>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR DATOS ALUMNO------------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-dato-alumno'>
                    <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM}</span>

                    <div>
                        <span>SEMESTRE: {grupo}</span>
                        <span>GRUPO: {semestre}</span>
                    </div>

                    <span>TURNO: {alumno.turno}</span>

                </div>

                <hr></hr>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR NOTIFICACIONES----------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-notificaciones'>
                <Link to={`/administrador/prefecto/reportes-alumno/${alumno.id}`} className='link-img'><img src={Icon_reporte}></img></Link>
                <Link to={`/administrador/prefecto/citatorios-alumno/${alumno.id}`} className='link-img'><img src={Icon_citatorio}></img></Link>
                <Link to={`/administrador/prefecto/faltas-alumno/${alumno.id}`} className='link-img'><img src={Icon_falta}></img></Link>
                <Link to={`/administrador/prefecto/retardos-alumno/${alumno.id}`} className='link-img'><img src={Icon_retardo}></img></Link>
                <Link to={`/administrador/prefecto/justificaciones-alumno/${alumno.id}`} className='link-img'><img src={Icon_justificacion}></img></Link>
                <Link to={`/administrador/prefecto/tareas-alumno/${alumno.id}`} className='link-img'><img src={Icon_tarea}></img></Link>

                </div>
            </div>
        </section>
                )}
                </>
            )}
            </>
        )}
        </>

    )
}

export default Notificaciones_prefecto