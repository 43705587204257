import './notificaciones-prefecto.css'
import Icon_atencion from '../assets/icon_atencion.png'
import Icon_visita from '../assets/icon_visita.png'
import Icon_atencionN from '../assets/icon_atencionN.png'
import Icon_visitaN from '../assets/icon_visitaN.png'
import { Link } from 'react-router-dom'

const Notificaciones_Orientadora = ({alumno, grupo, semestre, menu, setMenu}) => {

    console.log(alumno.id)
    return(

        <>
        {alumno && (
            <>
            {grupo && (
                <>
                {semestre && (
        <section className={`notificaciones-prefecto ${ menu ? 'estaActivo' : ''}`}>

            <div className='notificaciones-prefecto-contenedor'>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR DATOS ALUMNO------------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-dato-alumno'>
                    <span>{alumno.nombre} {alumno.apellidoP} {alumno.apellidoM} </span>

                    <div>
                        <span>SEMESTRE: {semestre} </span>
                        <span>GRUPO: {grupo} </span>
                    </div>

                    <span>TURNO: {alumno.turno} </span>

                </div>

                <hr></hr>
                {/*----------------------------------------------------------*/}
                {/*-----------------CONTENEDOR NOTIFICACIONES----------------*/}
                {/*----------------------------------------------------------*/}
                <div className='notificaciones-prefecto-contenedor-notificaciones'>
                    <Link to={`/administrador/orientadora/fais-alumno/${alumno.id}`} className='link-img'><img src={Icon_atencion}></img></Link>
                    <Link to={`/administrador/orientadora/fvds-alumno/${alumno.id}`} className='link-img'><img src={Icon_visita}></img></Link>
                </div>
            </div>
        </section>
                )}
                </>
            )}
            </>
        )}
        </>
    )
}

export default Notificaciones_Orientadora