import './tutor.css'
import Icon_tutor from '../assets/icon_tutores.png'


const Tutor = () => {
    return(
        <section className='tutor-contenedor'>
            <img src={Icon_tutor}>
            </img>
        </section>
    )
}

export default Tutor