import { useEffect, useState } from 'react';
import './datos-alumno.css'

const Datos_Alumno = () => {
    
    const [grupo, setGrupo] = useState ([])
    const [semestreN, setSemestreN] = useState ([])

    const nombre = JSON.parse(localStorage.getItem('nombre'));
    const matricula = JSON.parse(localStorage.getItem('matricula'));
    const apellidoP = JSON.parse(localStorage.getItem('apellidoP'));
    const apellidoM = JSON.parse(localStorage.getItem('apellidoM'));
    const semestre = localStorage.getItem("grupoSemestre");
   

    

    {useEffect(() => {
      // Asegurémonos de que usuario.cargo es un número antes de comparar
      const cargoNumero = parseInt(semestre);
  
      // Verificar el valor de cargoNumero y asignar el puesto correspondiente
      switch (cargoNumero) {
          case 1:
              setGrupo('1');
              setSemestreN('A')
              break;
          case 2:
              setGrupo('1');
              setSemestreN('B')
              break;
          case 3:
              setGrupo('2');
              setSemestreN('A')
              break;
          // Agrega más casos según sea necesario
          case 4:
              setGrupo('2');
              setSemestreN('B')
              break;
          case 5:
              setGrupo('3');
              setSemestreN('A')
          break;
          case 6:
              setGrupo('3');
              setSemestreN('B')
              break;
          case 7:
              setGrupo('4');
              setSemestreN('A')
              break;
          case 8:
              setGrupo('4');
              setSemestreN('B')
              break;
              // Agrega más casos según sea necesario
          case 9:
              setGrupo('5');
              setSemestreN('A')
              break;
          case 10:
              setGrupo('5');
              setSemestreN('B')
              break;
          case 11:
              setGrupo('6');
              setSemestreN('A')
              break;
          case 12:
              setGrupo('6');
              setSemestreN('B')
              break;
          // ... y así sucesivamente hasta 12 casos
          default:
              
              break;
      }
  }, [semestre]);}

    return(
       
        <section className='datos-alumno'>
            <div className='datos-alumno-contenedor'>            
                <span>{nombre} {apellidoP} {apellidoM}</span>

                <div>
                    <span>SEMESTRE: {grupo}</span>
                    <span>GRUPO: {semestreN}</span>
                </div>

                <span>TURNO: MATUTINO</span>
            </div>
        </section>
                
        
    )
}

export default Datos_Alumno