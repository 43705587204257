import Alerta_EliminarF from '../alerts/alertaEliminarR'
import Cabecera from '../components/cabecera'
import Retardo from '../components/retardo'

import { useState } from "react"

const Retardos = () => {
    const [alerta, setAlerta ] = useState (false)
    return(
        <>
        <Cabecera/>
        <Retardo alerta={alerta} setAlerta={setAlerta}/>
        <Alerta_EliminarF alerta={alerta} setAlerta={setAlerta}/>
       
        </>
    )
}

export default Retardos