import CabeceraT from '../components/cabeceraT.js'
import Detalles_Citatorio from '../components/detalles-citatorio.js'


const Ver_CitatorioT = () => {

    return(
        <>
        <CabeceraT/> 
        <Detalles_Citatorio/>
        </>
    )
}

export default Ver_CitatorioT