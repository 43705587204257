import CabeceraO from "../components/cabeceraO"
import Formulario_Tutor from "../components/formulario-tutor"

const Agregar_Tutor = () => {
    return(
        <>
            <CabeceraO/>            
            <Formulario_Tutor/>
        </>    
    )
}
export default Agregar_Tutor