import CabeceraD from "../components/cabeceraD"
import Formulario_Usuario from "../components/formulario-usuario"

const Agregar_Usuario = () => {
    return(
        <>
            <CabeceraD/>
            <Formulario_Usuario/>
        </>    
    )
}
export default Agregar_Usuario