import CabeceraD from "../components/cabeceraD"
import Tutor from "../components/tutor"
import Contrasenas from "../components/contrasenas"
import Datos_Tutor from "../components/datos-tutor"
import Buscador_Tutor_Contraseña from "../components/burcador-tutor-contraseña"
import Alerta_Sin_Tutor from "../alerts/alertaSinTutor"

import { useState } from "react"



const Directora = () => {

    const [tutor, setTutor] = useState ([]);
    const [menu, setMenu] = useState (true)
    const [alerta, setAlerta] = useState (false)

    return(
        <>
            <CabeceraD/>
            <h2>CAMBIAR CONTRASEÑAS</h2>
            <Tutor/>
            <Buscador_Tutor_Contraseña tutor={tutor} setTutor={setTutor} menu={menu} setMenu={setMenu} alerta={alerta} setAlerta={setAlerta}/>
            <Datos_Tutor tutor={tutor} />
            <Contrasenas tutor={tutor} menu={menu} setMenu={setMenu}/>
            <Alerta_Sin_Tutor alerta={alerta} setAlerta={setAlerta}/>
        </>
    )
}

export default Directora